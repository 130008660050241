import Box from '@material-ui/core/Box'
import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import StyledStepConnector from './StyledStepConnector.js'

function CngStepper(props) {
  const { children, stepConnectorProps, ...stepperProps } = props

  return (
    <Stepper
      bgcolor='transparent'
      component={Box}
      connector={<StyledStepConnector {...stepConnectorProps} />}
      {...stepperProps}
    >
      {children}
    </Stepper>
  )
}

export default CngStepper
