import { Box, Button, Grid, Typography } from '@material-ui/core'

import React from 'react'
import RestoreIcon from '@material-ui/icons/Restore'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

const FilterHeader = (props) => {
  const translatedTextsObject = SearchScheduleTranslationText()

  return (
    <Grid container='true' item md={12} alignItems='center'>
      <CngGridItem xs={12}>
        <Box display='flex'>
          <Box flexGrow={1}>
            <Typography class={'rs-filter-header'}>
              {translatedTextsObject.filters}
            </Typography>
          </Box>
          <Box pb={1}>
            <Button
              name='clear'
              startIcon={<RestoreIcon />}
              className={'clear-all-button'}
              size='small'
              onClick={props.handleClearAllButtonClick}
            >
              {translatedTextsObject.clearAll}
            </Button>
          </Box>
        </Box>
      </CngGridItem>
    </Grid>
  )
}

export default FilterHeader
