import { Box, Grid, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'

import PreferredSettingMenu from './PreferredSettingMenu'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import { components } from 'cng-web-lib'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngRadioGroupField }
  },
  CngGridItem
} = components

const PreferredFilterComponent = (props) => {
  const [, , { setValue: setPreferred }] = useField('preferred')
  const [, , { setValue: setPollingId }] = useField('pollingId')
  const translatedTextsObject = SearchScheduleTranslationText()
  const preferredOptions = [
    { label: translatedTextsObject.allCarrier, value: 'DEFAULT' },
    { label: translatedTextsObject.preferredCarrier, value: 'CARRIER' },
    { label: translatedTextsObject.preferredPortPair, value: 'PORT' }
  ]

  const { submitForm } = useFormikContext()

  useEffect(() => {
    setPreferred(props.preferred)
  }, [])

  return (
    <Grid container='true' item className={'rs-filter-card'}>
      <CngGridItem xs={12} className={'rs-bg-grey'}>
        <Box display='flex'>
          <Box mt={1} flexGrow={1}>
            <Typography className={'rs-filter-subheader'}>
              {translatedTextsObject.preferred}
            </Typography>
          </Box>
          <Box>
            <PreferredSettingMenu />
          </Box>
        </Box>
      </CngGridItem>
      <CngGridItem xs={12}>
        <CngRadioGroupField
          name='preferred'
          options={preferredOptions}
          direction='column'
          onChange={(e) => {
            setPollingId('')
            submitForm()
          }}
        />
      </CngGridItem>
    </Grid>
  )
}

export default PreferredFilterComponent
