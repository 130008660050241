import Namespace from 'src/constants/locale/Namespace'
import React from 'react'
import SearchScheduleKeys from 'src/constants/locale/key/SearchSchedule'
import { useTranslation } from 'cng-web-lib'

const SearchScheduleTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.SEARCH_SCHEDULE
  ])

  let SearchSchedule = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.TITLE
  )
  let portFrom = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.PORT_FROM
  )
  let portTo = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.PORT_TO)
  let departDate = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CARGO_DEPART_DATE
  )
  let arrivalDate = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CARGO_ARRIVAL_DATE
  )
  let version = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.VERSION)
  let previouslySearch = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.PREVIOUSLY_SEARCH
  )
  let search = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.SEARCH)
  let preferred = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.PREFERRED
  )
  let noResult = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.NORESULT
  )
  let noResultFound = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.NORESULTFOUND
  )
  let sendUsRequest = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.SENDUSREQUEST
  )
  let carrier = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.CARRIER)
  let vessel = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.VESSEL)
  let service = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.SERVICE)
  let transitTime = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.TRANSITTIME
  )
  let cyCutOff = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CYCUTOFF
  )

  let terminal = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.TERMINAL
  )
  let departPOL = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.DEPARTPOL
  )
  let arrivePOD = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.ARRIVEPOD
  )
  let book = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.BOOK)
  let days = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.DAYS)
  let direct = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.DIRECT)
  let transportVia = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.TRANSPORTVIA
  )
  let departure = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.DEPARTURE
  )
  let arrive = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.ARRIVE)
  let voyagerDetails = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.VOYAGERDETAILS
  )
  let fastest = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.FASTEST)
  let earliest = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.EARLIEST
  )
  let filters = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.FILTERS)

  let clearAll = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CLEAR_ALL
  )
  let allCarrier = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.ALL_CARRIER
  )
  let preferredCarrier = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.PREFERRED_CARRIER
  )
  let preferredPortPair = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.PREFERRED_PORT_PAIR
  )
  let blacklistedCarriers = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.BLACKLISTED_CARRIERS
  )
  let directTranshipment = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.DIRECT_TRANSHIPMENT
  )
  let directOnly = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.DIRECT_ONLY
  )
  let noResultFoundAfterFilter = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.NORESULTFOUNDAFTERFILTER
  )
  let orSendUsYour = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.ORSENDUSYOUR
  )
  let request = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.REQUEST)
  let resetFilters = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.RESETFILTERS
  )
  let requestCarrierConnection = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.REQUESTCARRIERCONNECTION
  )
  let selectCarrier = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.SELECTCARRIER
  )
  let enterCarrierInformation = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.ENTERCARRIERINFORMATION
  )
  let getNotified = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.GETNOTIFIED
  )
  let notifyMe = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.NOTIFYME
  )
  let emailAddress = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.EMAILADDRESS
  )
  let carrierInfo1 = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CARRIERINFO1
  )
  let carrierInfo2 = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CARRIERINFO2
  )
  let carrierInfo3 = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CARRIERINFO3
  )
  let bookingNumber = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.BOOKINGNUMBER
  )
  let annualShippment = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.ANNUALSHIPMENT
  )
  let contractNumber = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CONTRACTNUMBER
  )
  let customerNumber = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CUSTOMERNUMBER
  )
  let blNumber = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.BLNUMBER
  )
  let govId = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.GOVID)
  let comment4Carrier = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.COMMENT4CARRIER
  )
  let detailOfSearch = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.DETAILOFSEARCH
  )
  let dialogContent = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.DIALOGCONTENT
  )
  let dialogOK = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.DIALOGOK
  )
  let dialogCancel = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.DIALOGCANCEL
  )
  let dialogTitle = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.DIALOGTITLE
  )
  let connectCarrier = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CONNECTCARRIER
  )
  let searchCompleted = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.SEARCHCOMPLETED
  )
  let refreshTheResult = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.REFRESHTHERESULT
  )
  let cancel = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.CANCEL)
  let refresh = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.REFRESH)
  let refreshNote = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.REFRESHNOTE
  )
  let exportText = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.EXPORTTEXT
  )
  let continueText = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CONTINUETEXT
  )
  let sendRequest = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.SENDREQUEST
  )
  let vesselSchedule = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.VESSELSCHEDULE
  )
  let cfcTonPerTeu = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CFCTONPERTEU
  )
  let cO2EmissionTotal = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CO2EMISSIONTOTAL
  )
  let cfcTonsUnit = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CFCTONSUNIT
  )
  let energyConsumption = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.ENERGYCONSUMPTION
  )
  let cfcMegajouleUnit = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.CFCMEGAJOULEUNIT
  )

  let weeks = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.WEEKS)
  let plus = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.PLUS)
  let minus = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.MINUS)
  let voyageStatus = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.VOYAGESTATUS
  )

  return {
    SearchSchedule,
    portFrom,
    portTo,
    departDate,
    arrivalDate,
    version,
    previouslySearch,
    search,
    preferred,
    noResult,
    noResultFound,
    sendUsRequest,
    carrier,
    vessel,
    service,
    transitTime,
    cyCutOff,
    terminal,
    departPOL,
    arrivePOD,
    book,
    days,
    direct,
    transportVia,
    departure,
    arrive,
    voyagerDetails,
    fastest,
    earliest,
    filters,
    clearAll,
    allCarrier,
    preferredCarrier,
    preferredPortPair,
    blacklistedCarriers,
    directTranshipment,
    directOnly,
    noResultFoundAfterFilter,
    orSendUsYour,
    request,
    resetFilters,
    requestCarrierConnection,
    selectCarrier,
    enterCarrierInformation,
    getNotified,
    notifyMe,
    emailAddress,
    carrierInfo1,
    carrierInfo2,
    carrierInfo3,
    bookingNumber,
    annualShippment,
    contractNumber,
    customerNumber,
    blNumber,
    govId,
    comment4Carrier,
    detailOfSearch,
    dialogContent,
    dialogOK,
    dialogCancel,
    dialogTitle,
    connectCarrier,
    searchCompleted,
    refreshTheResult,
    refreshNote,
    refresh,
    cancel,
    exportText,
    continueText,
    sendRequest,
    vesselSchedule,
    weeks,
    plus,
    minus,
    voyageStatus,
    cfcTonPerTeu,
    cO2EmissionTotal,
    cfcTonsUnit,
    energyConsumption,
    cfcMegajouleUnit
  }
}

export default SearchScheduleTranslationText
