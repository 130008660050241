import React, { useState } from 'react'

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined'
import Typography from '@material-ui/core/Typography'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import { useHistory } from 'react-router-dom'

export default function PreferredSettingMenu() {
  const translatedTextsObject = SearchScheduleTranslationText()
  const [anchorEl, setAnchorEl] = useState(null)
  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <SettingsOutlinedIcon />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            history.push(pathMap.TCAL_VS_MANAGE_PREF_CAR_LIST_VIEW)
          }
        >
          <ListItemIcon>
            <FavoriteBorderIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit' style={{ fontSize: 'small' }}>
            {translatedTextsObject.preferredCarrier}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => history.push(pathMap.TCAL_VS_MANAGE_PREF_PP_LIST_VIEW)}
        >
          <ListItemIcon>
            <SyncAltOutlinedIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit' style={{ fontSize: 'small' }}>
            {translatedTextsObject.preferredPortPair}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}
