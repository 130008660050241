import { Step, StepConnector, StepLabel, Stepper } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import PortCountryViewField from 'src/components/field/PortCountryViewField'
import PropTypes from 'prop-types'
import React from 'react'
import StopPointIcon from '@material-ui/icons/FiberManualRecordOutlined'
import clsx from 'clsx'

const PortsStepper = (props) => {
  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)'
    },
    active: {
      '& $line': {
        borderColor: '#eaeaf0'
      }
    },
    completed: {
      '& $line': {
        borderColor: '#eaeaf05'
      }
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1
    }
  })(StepConnector)

  const QontoStepIcon = (first) => (props) => {
    const classes = makeStyles({
      root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center'
      },
      active: {
        color: '#784af4'
      },
      circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
      },
      completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18
      }
    })
    const { active, completed } = props

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active
        })}
      >
        {first ? (
          <img
            src={
              process.env.PUBLIC_URL +
              '/static/images/vesselSchedule/startport.svg'
            }
          />
        ) : (
          <StopPointIcon className={classes.completed} />
        )}
      </div>
    )
  }

  QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool
  }

  return (
    <Stepper activeStep={30} connector={<QontoConnector />} alternativeLabel>
      <Step>
        <StepLabel StepIconComponent={QontoStepIcon(true)}></StepLabel>
      </Step>
      {props.voyageLegs
        .filter(
          (voyageLeg) => voyageLeg.voyageId === props.shipperScheduleVoyageId
        )
        .map((item) =>
          item.content
            .filter((leg) => {
              return (
                props.skipFilter ||
                leg.transportMode === null ||
                item.check[
                  item.check.findIndex(
                    (c) =>
                      c.key ===
                      leg.voyageId +
                        leg.schVoyageNo +
                        leg.schVesselName +
                        leg.transportMode
                  )
                ].endSeq === leg.seqNo ||
                item.check[
                  item.check.findIndex(
                    (c) =>
                      c.key ===
                      leg.voyageId +
                        leg.schVoyageNo +
                        leg.schVesselName +
                        leg.transportMode
                  )
                ].startSeq === leg.seqNo
              )
            })
            .map((leg) =>
              leg.listOut ? (
                <Step>
                  <StepLabel StepIconComponent={QontoStepIcon(false)}>
                    <PortCountryViewField
                      countryList={props.countryList}
                      portCode={leg.portCode}
                      breakPortCode
                    />
                  </StepLabel>
                </Step>
              ) : null
            )
        )}
    </Stepper>
  )
}

export default PortsStepper
