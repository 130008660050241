import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField.js'
import React from 'react'

function SelectCarrierStep() {
  return (
    <CarrierAutocompleteField name='carrierPartyId' label='Carrier' disabled />
  )
}

export default SelectCarrierStep
