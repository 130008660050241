import VsMigrationSyncLogApiUrls from 'src/apiUrls/VsMigrationSyncLogApiUrls'
import VsMigrationSyncLogFormProperties from './VsMigrationSyncLogFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: { CngAddForm }
} = components

function VsMigrationSyncLogAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
      history={history}
      showNotification={showNotification}
      bodySection={
        <VsMigrationSyncLogFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={VsMigrationSyncLogFormProperties.formikProps}
      toClientDataFormat={VsMigrationSyncLogFormProperties.toClientDataFormat}
      toServerDataFormat={VsMigrationSyncLogFormProperties.toServerDataFormat}
      create={{
        url: VsMigrationSyncLogApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default VsMigrationSyncLogAddForm
