import { Box, Grid, Slider, Tooltip, Typography } from '@material-ui/core'

import React from 'react'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

const TransitTimeFilterComponents = (props) => {
  const translatedTextsObject = SearchScheduleTranslationText()

  return (
    <Grid container='true' item className={'rs-filter-card'}>
      <CngGridItem className={'rs-bg-grey'} xs={12}>
        <Typography
          className={'rs-filter-subheader'}
        >{`${translatedTextsObject.transitTime} (${translatedTextsObject.days})`}</Typography>
      </CngGridItem>
      <CngGridItem xs={12}>
        <Box mx={3.5} mt={1}>
          <Slider
            disabled={props.transitTime.length === 0}
            value={
              props.transitTime.length > 0
                ? [props.transitTime[0].value, props.transitTime[1].value]
                : [0, 0]
            }
            valueLabelFormat={(value) => {
              if (props.transitTime.length <= 0) {
                return value
              }

              const day = props.transitTime.find((day) => day.value === value)
              return day ? day.label : value
            }}
            ValueLabelComponent={ValueLabelTooltip}
            onChange={props.handleTransitTimeSliderMoveChange}
            onChangeCommitted={props.handleTransitTimeSliderCommittedChange}
            valueLabelDisplay='auto'
            min={props.minMaxValue.length > 0 ? props.minMaxValue[0].value : 0}
            max={props.minMaxValue.length > 0 ? props.minMaxValue[1].value : 0}
            marks={
              props.minMaxValue.length > 0
                ? props.minMaxValue.map((day) => {
                    return {
                      value: day.value,
                      label: day.label
                    }
                  })
                : []
            }
          />
        </Box>
      </CngGridItem>
    </Grid>
  )
}

function ValueLabelTooltip(props) {
  const { children, open, value } = props

  return (
    <Tooltip open={open} enterTouchDelay={0} placement='top' title={value}>
      {children}
    </Tooltip>
  )
}

export default TransitTimeFilterComponents
