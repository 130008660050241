import { Box } from '@material-ui/core'
import React from 'react'
const WordBox = ({ label, value, unit, isTonPerTeu }) => {

    return (
        <Box
            style={{
                justifyContent: 'center',
                backgroundColor: '#8996af1a',
                height: '45px',
                borderRadius: '10px',
                flexDirection: 'column',
                paddingLeft: '10px',
                display: 'flex'
            }}>
            {isTonPerTeu ?
                <Box
                    className='bold'
                    style={{
                        color: '#007a33',
                        fontSize: '16px',
                        width: '540px'
                    }}
                >
                    {label}
                </Box>
                :
                <Box
                    className='bold'
                    style={{
                        color: '#007a33',
                        fontSize: '16px',
                        width: '450px'
                    }}
                >{label}{value}{unit}
                </Box>}
        </Box>
    )
}

export default WordBox