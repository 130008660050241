import Box from '@material-ui/core/Box'
import React from 'react'
import SearchScheduleTranslationText from '../SearchScheduleTranslationText'
import { Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'

const {
  form: {
    field: { CngTextField }
  }
} = components

function EnterCarrierInformationStep() {
  const translatedTextsObject = SearchScheduleTranslationText()
  return (
    <Box>
      <Box>
        <Typography variant='body2' gutterBottom>
          {translatedTextsObject.carrierInfo1}
        </Typography>
        <Typography variant='body2' gutterBottom>
          {translatedTextsObject.carrierInfo2}
        </Typography>
        <Typography variant='body2' gutterBottom>
          {translatedTextsObject.carrierInfo3}
        </Typography>
      </Box>
      <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
        <Box width={0.32} my={1}>
          <CngTextField
            name='bookRefNo'
            label={translatedTextsObject.bookingNumber}
            inputProps={{ maxLength: 50 }}
          />
        </Box>
        <Box width={0.32} my={1}>
          <CngTextField
            name='annualShipmentTeu'
            label={translatedTextsObject.annualShippment}
            inputProps={{ maxLength: 50 }}
          />
        </Box>
        <Box width={0.32} my={1}>
          <CngTextField
            name='contractNo'
            label={translatedTextsObject.contractNumber}
            inputProps={{ maxLength: 50 }}
          />
        </Box>
        <Box width={0.32} my={1}>
          <CngTextField
            name='custNo'
            label={translatedTextsObject.customerNumber}
            inputProps={{ maxLength: 50 }}
          />
        </Box>
        <Box width={0.32} my={1}>
          <CngTextField
            name='blNo'
            label={translatedTextsObject.blNumber}
            inputProps={{ maxLength: 50 }}
          />
        </Box>
        <Box width={0.32} my={1}>
          <CngTextField
            name='govTaxId'
            label={translatedTextsObject.govId}
            inputProps={{ maxLength: 50 }}
          />
        </Box>
        <Box width={1} my={1}>
          <CngTextField
            name='comments'
            label={translatedTextsObject.comment4Carrier}
            multiline
            rows={3}
            inputProps={{ maxLength: 500 }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default EnterCarrierInformationStep
