import React, { useState } from 'react'

import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import SendUsFeedbackForm from './SendUsFeedbackForm'
import { Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'

const { CngDialog } = components

function SendUsFeedbackDialog({
  isFeedbackDialogOpen,
  closeDialog,
  showNotification,
  countryNameByCode
}) {
  const history = useHistory()
  const [isSubmitting, setSubmitting] = useState(false)

  const {
    arrivalDate,
    departDate,
    from,
    to,
    fromCountryCode,
    toCountryCode
  } = history.location.state

  function updateSubmitting(value) {
    setSubmitting(value)
  }

  return (
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={2} mt={1}>
            <Box mt={0.5}>
              <Typography variant='body2'>
                {'The schedule from '}
                <b>
                  {from} ({departDate})
                </b>
                {' to '}
                <b>
                  {to} ({arrivalDate})
                </b>
                {
                  ' could not be found at this time. If you know that a supported carrier offers schedules for this route, please let us know. We appreciate your feedback.'
                }
              </Typography>
            </Box>
            <Box mt={1}>
              <Voyage
                voyagePOLProps={{
                  countryCode: fromCountryCode,
                  countryName: countryNameByCode[from],
                  etd: departDate,
                  portCode: from
                }}
                voyagePODProps={{
                  countryCode: toCountryCode,
                  countryName: countryNameByCode[to],
                  eta: arrivalDate,
                  portCode: to
                }}
              />
            </Box>
            <SendUsFeedbackForm
              arrivalDate={arrivalDate}
              departDate={departDate}
              from={from}
              to={to}
              closeDialog={closeDialog}
              isSubmitting={isSubmitting}
              updateSubmitting={updateSubmitting}
              showNotification={showNotification}
            />
          </Box>
        </Paper>
      }
      dialogTitle={
        <>
          <b>Send us your feedback</b>
          <CloseDialogIconButton
            onClick={closeDialog}
            disabled={isSubmitting}
          />
        </>
      }
      open={isFeedbackDialogOpen}
      fullWidth
      maxWidth='sm'
    />
  )
}

function Voyage({ voyagePOLProps, voyagePODProps }) {
  return (
    <Box display='flex' justifyContent='center'>
      <Box display='flex' flexGrow={1}></Box>
      <VoyagePOL {...voyagePOLProps} />
      <Box
        display='flex'
        alignItems='center'
        alignContent='stretch'
        flexGrow={1}
      >
        <Box minWidth={1}>
          <hr />
        </Box>
      </Box>
      <VoyagePOD {...voyagePODProps} />
      <Box display='flex' flexGrow={1}></Box>
    </Box>
  )
}

function VoyagePOL(props) {
  return <VoyagePortDetails flexDirection={'row'} {...props} />
}

function VoyagePOD(props) {
  return <VoyagePortDetails flexDirection={'row-reverse'} {...props} />
}

function VoyagePortDetails({
  countryName,
  countryCode,
  eta,
  etd,
  flexDirection,
  portCode
}) {
  return (
    <Box border={1} borderColor='color.lightGrey' borderRadius={50} p={1}>
      <Box display='flex' flexDirection={flexDirection}>
        <Box display='flex' alignItems='center' mx={0.25}>
          <Avatar
            style={{ width: 20, height: 20 }}
            alt={countryCode}
            src={`${process.env.PUBLIC_URL}/static/images/countries/${countryCode}.svg`}
          />
        </Box>
        <Box display='flex' alignItems='center'>
          <Typography variant='subtitle1'>
            {countryName || <CircularProgress color='primary' size={10} />}
          </Typography>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          mx={0.5}
        >
          <Chip label={<b>{portCode}</b>} size='small' />
        </Box>
      </Box>
      <Box display='flex' justifyContent='center' mt={0.25}>
        <Typography variant='caption'>{eta || etd}</Typography>
      </Box>
    </Box>
  )
}

export default SendUsFeedbackDialog
