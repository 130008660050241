import React, { useState } from 'react'

import CngStepLabel from './CngStepLabel.js'
import CngStepper from './CngStepper.js'
import PropTypes from 'prop-types'
import Step from '@material-ui/core/Step'
import StepperContext from './StepperContext.js'
import StyledStepContent from './StyledStepContent.js'
import useStepperHandler from './useStepperHandler.js'

function CngWizard({
  disabledSteps,
  steps,
  hideCompleted,
  stepperProps,
  stepConnectorProps,
  stepProps,
  stepLabelProps,
  stepContentProps
}) {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})

  const { isLastStep, handleBack, handleNext, handleStep } = useStepperHandler(
    steps,
    activeStep,
    setActiveStep,
    completed,
    setCompleted,
    disabledSteps
  )

  return (
    <StepperContext.Provider
      value={{
        activeStep,
        completed,
        isLastStep,
        handleBack,
        handleNext,
        handleStep
      }}
    >
      <CngStepper
        activeStep={activeStep}
        stepConnectorProps={stepConnectorProps}
        {...stepperProps}
      >
        {steps.map((step) => {
          return (
            <Step key={step.label} {...stepProps}>
              <CngStepLabel
                step={step}
                StepIconProps={{
                  Icon: step.icon,
                  hideCompleted: hideCompleted
                }}
                {...stepLabelProps}
              />
              {stepperProps?.orientation === 'vertical' && (
                <StyledStepContent {...stepContentProps}>
                  {step.content}
                </StyledStepContent>
              )}
            </Step>
          )
        })}
      </CngStepper>
      {stepperProps?.orientation !== 'vertical' && steps[activeStep].content}
    </StepperContext.Provider>
  )
}

CngWizard.propTypes = {
  disabledSteps: PropTypes.array,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
      icon: PropTypes.elementType,
      label: PropTypes.string,
      multipleSection: PropTypes.bool,
      topContent: PropTypes.element,
      middleContent: PropTypes.element,
      bottomContent: PropTypes.element
    })
  )
}

export default CngWizard
