import { CircularProgress } from '@material-ui/core'
import React from 'react'

const Loading = (props) => {
  return props.loading ? (
    <div className={!props.css ? 'vs-noresult' : ''}>
      <CircularProgress />
    </div>
  ) : null
}

export default Loading
