import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import VsMigrationSyncLogViewForm from './VsMigrationSyncLogViewForm'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import VsMigrationSyncLogKeys from 'src/constants/locale/key/VsMigrationSyncLog'

const { CngTabs } = components


function ViewPage({ showNotification }) {
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.VS_MIGRATION_SYNC_LOG])
  const translatedTextsObject = makeTranslatedTextsObject()
  const [vsMigrationSyncLog, setVsMigrationSyncLog] = useState({})

  function makeTranslatedTextsObject() {
    let vsMigrationSyncLog = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.TITLE
    )

    return {
      vsMigrationSyncLog
    }
  }

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <VsMigrationSyncLogViewForm
              showNotification={showNotification}
              id={id}
              onFetchPreSuccess={(datum) => {
                setVsMigrationSyncLog(datum)
              }}
              items={vsMigrationSyncLog}
            />
          </Container>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.vsMigrationSyncLog,
              tabContent: (
                <VsMigrationSyncLogViewForm
                  showNotification={showNotification}
                  id={id}
                  onFetchPreSuccess={(datum) => {
                    setVsMigrationSyncLog(datum)
                  }}
                  items={vsMigrationSyncLog}
                />
              )
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ViewPage