import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import {
  DataFlattener,
  DateTimeFormatter,
  components,
  constants,
  useServices
} from 'cng-web-lib'
import { Fragment, useEffect, useState } from 'react'
import React, { useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Utils from 'src/views/common/utils/Utils'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CngBackdrop from './cngcomponent/CngBackDrop'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import DirectionsBoatOutlinedIcon from '@material-ui/icons/DirectionsBoatOutlined'
import ExpandMenus from './ExpandMenu'
import FilterPanel from './FilterPanel'
import FlightIcon from '@material-ui/icons/Flight'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ManageCarrierConnectionDialog from './managecarrierconnection/ManageCarrierConnectionDialog.js'
import ManageScheduleTranslationText from 'src/views/vesselschedule/manageschedule/ManageScheduleTranslationText'
import NoResult from './NoResult'
import NoResultProgressBar from './NoResultProgressBar'
import PollingProgressBar from './PollingProgressBar'
import PortCountryViewField from 'src/components/field/PortCountryViewField'
import PortsStepper from './PortsStepper'
import ResultPageTablePagination from './ResultPageTablePagination'
import SearchPanel from './SearchPanel'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import TerminalInitial from '../component/TerminalInitial'
import TrainOutlinedIcon from '@material-ui/icons/TrainOutlined'
import makeValidationSchema from './MakeValidationSchema'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_FreightBooking'
import searchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls'
import { withStyles } from '@material-ui/core/styles'
import HtmlTooltip from 'src/views/freightbooking/components/HtmlTooltip'
import carbonFootprintApiUrls from 'src/apiUrls/CarbonFootprintApiUrls'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  button: { CngPrimaryButton, CngSecondaryButton },
  CngGridItem
} = components

const { CodeMaintenanceType } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  departDate: DateTimeFormatter.toClientDate(new Date()),
  arrivalDate: DateTimeFormatter.toClientDate(
    new Date(
      new Date().setTime(new Date().getTime() + 4 * 7 * 24 * 60 * 60 * 1000)
    )
  ),
  preferred: '',
  from: '',
  to: '',
  fromCountryCode: '',
  toCountryCode: '',
  pollingId: '',
  weeks: 4
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const transportMode = new Map([
  ['R', 'RAIL'],
  ['L', 'RAIL'],
  ['T', 'TRUCK'],
  ['V', 'VOYAGE'],
  ['B', 'VOYAGE'],
  ['F', 'FEEDER'],
  ['A', 'AIR']
])

function Fields({ disabled, showNotification, shouldHideMap }) {
  const translatedTextsObject = SearchScheduleTranslationText()
  const manageScheduleTranslationText = ManageScheduleTranslationText()
  const { fetchRecords } = useServices()
  const [shipperSchedules, setShipperSchedules] = useState([])
  const [originalShipperSchedules, setOriginalshipperSchedules] = useState([])
  const [voyageLegs, setVoyageLegs] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [earlierClick, setEarlierClick] = useState(true)
  const [fasterClick, setFasterClick] = useState(false)
  const portRef = useRef([])
  const location = useLocation()
  const history = useHistory()
  const [carriers, setCarriers] = useState([])
  const [transitTime, setTransitTime] = useState([])
  const [minMaxValue, setMinMaxValue] = useState([])
  const [cyCutOffRange, setCyCutOffRange] = useState([])
  const [minMaxDateValue, setMinMaxDateValue] = useState([])
  const [pollingCount, setPollingCount] = useState(0)
  const [initialShipperSchedulesLength, setInitialShipperSchedulesLength] = useState(0)
  // const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmDialogOpen, , { setValue: setConfirmDialogOpen }] =
    useField('confirmDialogOpen')
  const [showRefreshButton, setShowRefreshButton] = useState(false)
  const sortedCyCutOffArray = useRef([])
  const sortedTransitTimeArray = useRef([])
  const runFilter = useRef(false)
  const [directOnly, setDirectOnly] = useState(false)
  const directOnlyCount = useRef(0)
  const [country, setCountry] = useState([])
  const [
    isManageCarrierConnectionDialogOpen,
    setManageCarrierConnectionDialogOpen
  ] = useState(false)
  const [connectCarrierPartyId, setConnectCarrierPartyId] = useState()
  const pollingProgress = useRef([])
  const voyageLegsSchedule = useRef([])
  const [, , { setValue: setPollingId }] = useField('pollingId')
  const [weeksField, ,] = useField('weeks')
  const [fromField, ,] = useField('from')
  const [toField, ,] = useField('to')
  const [departDatefield, ,] = useField('departDate')
  const { submitForm, setFieldValue } = useFormikContext()
  const [cfcResults, setCfcResults] = useState([])
  const [columns, setColumns] = useState([
    {
      name: translatedTextsObject.carrier,
      active: false,
      sortfieldName: 'carrierName'
    },
    {
      name: `${translatedTextsObject.vessel}/ ${translatedTextsObject.service}`,
      active: false,
      sortfieldName: 'vesselName'
    },
    {
      name: translatedTextsObject.transitTime,
      active: false,
      sortfieldName: 'transit'
    },
    {
      name: translatedTextsObject.cyCutOff,
      active: false,
      sortfieldName: 'cyCutoff'
    },
    {
      name: translatedTextsObject.departPOL,
      active: false,
      sortfieldName: 'polEta'
    },
    {
      name: translatedTextsObject.arrivePOD,
      active: true,
      sortfieldName: 'podEta',
      order: 'desc'
    }
  ])

  let searchCriteria = {
    cargoReadyDate: location.state.departDate,
    rangeOfCargoReadyDate: location.state.arrivalDate,
    portOfLoading: location.state.from,
    portOfDischarge: location.state.to,
    searchType:
      location.state.preferred !== undefined
        ? location.state.preferred
        : 'DEFAULT',
    progressive: true,
    pollingId: location.state.pollingId,
    weeks: location.state.weeks,
    autoRefresh: JSON.parse(localStorage.getItem('autoRefresh') || 'false')
  }

  useEffect(() => {
    setFieldValue('from', location.state.from)
    setFieldValue('to', location.state.to)
    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.COUNTRY,
      [],
      false,
      onLoadCountrySuccess
    )

    function onLoadCountrySuccess(data) {
      setCountry(data)
    }

    fetchRecords.execute(
      searchScheduleApiUrls.SHIPPER_VESSEL_SCHEDULE,
      {
        customData: searchCriteria
      },
      (data) => {
        let FromApi = data.content.map((v) => ({ ...v, expand: false }))
        setInitialShipperSchedulesLength(FromApi.length)
        const { totalSource, processedSource, pollingId } = data.pollingProgress
        pollingProgress.current = [totalSource, processedSource, pollingId]
        if (typeof location.state.pollingId !== 'number') {
          setPollingCount(pollingCount + 1)
        }
        setShipperSchedules(FromApi)
        setOriginalshipperSchedules(FromApi)
        setLoading(false)
        let TransitTimeSet = new Set(FromApi.map((item) => item.transit))
        let cyCutOffDateSet = new Set(
          FromApi.filter((item) =>
            moment(item.cyCutoff, moment.ISO_8601, true).isValid()
          ).map((item) => moment(item.cyCutoff).format('YYYY-MM-DD'))
        )

        directOnlyCount.current = FromApi.filter(
          (item) => item.directShipment
        ).length

        if (cyCutOffDateSet.size > 0) {
          const sortedArray = [...Array.from(cyCutOffDateSet.values())]
            .slice()
            .sort()
          sortedCyCutOffArray.current = sortedArray.map((item, index) => ({
            value: index,
            label: moment(item).format('D MMM YYYY')
          }))
          console.log(sortedCyCutOffArray.current)
          setCyCutOffRange([
            {
              value: sortedCyCutOffArray.current[0].value,
              label: sortedCyCutOffArray.current[0].label
            },
            {
              value:
                sortedCyCutOffArray.current[
                  sortedCyCutOffArray.current.length - 1
                ].value,
              label:
                sortedCyCutOffArray.current[
                  sortedCyCutOffArray.current.length - 1
                ].label
            }
          ])
          setMinMaxDateValue([
            {
              value: sortedCyCutOffArray.current[0].value,
              label: sortedCyCutOffArray.current[0].label
            },
            {
              value:
                sortedCyCutOffArray.current[
                  sortedCyCutOffArray.current.length - 1
                ].value,
              label:
                sortedCyCutOffArray.current[
                  sortedCyCutOffArray.current.length - 1
                ].label
            }
          ])
        }

        if (TransitTimeSet.size > 0) {
          sortedTransitTimeArray.current = [
            ...Array.from(TransitTimeSet.values())
          ]
            .slice()
            .sort(
              (a, b) =>
                (typeof b === 'number') - (typeof a === 'number') ||
                (a > b ? 1 : -1)
            )
            .map((item, index) => ({
              value: index,
              label: item
            }))
          setTransitTime([
            {
              value: sortedTransitTimeArray.current[0].value,
              label: sortedTransitTimeArray.current[0].label
            },
            {
              value:
                sortedTransitTimeArray.current[
                  sortedTransitTimeArray.current.length - 1
                ].value,
              label:
                sortedTransitTimeArray.current[
                  sortedTransitTimeArray.current.length - 1
                ].label
            }
          ])
          setMinMaxValue([
            {
              value: sortedTransitTimeArray.current[0].value,
              label: sortedTransitTimeArray.current[0].label
            },
            {
              value:
                sortedTransitTimeArray.current[
                  sortedTransitTimeArray.current.length - 1
                ].value,
              label:
                sortedTransitTimeArray.current[
                  sortedTransitTimeArray.current.length - 1
                ].label
            }
          ])
        }
        FetchCarrierList()
      },
      (error) => {
        console.log(error)
        setLoading(false)
      }
    )

    function FetchCarrierList() {
      fetchRecords.execute(
        searchScheduleApiUrls.SHIPPER_LIST_CARRIER,
        {
          customData: searchCriteria
        },
        (data) => {
          let FromApi = data.content.map((v) => ({ ...v, checked: true }))
          if (FromApi[0] !== undefined) {
            let newRecord = { ...FromApi[0] }
            newRecord.name = 'ALL'
            newRecord.count = FromApi.reduce((a, b) => a + b.count, 0)
            FromApi = [...FromApi, newRecord]
          }
          setCarriers(FromApi)
        },
        (error) => {
          console.log(error)
        }
      )
    }
    const returnValue = {
      cargoRequest: {
        weight: 1
      },
      routeRequest: {
        departure: { unLocode: searchCriteria.portOfLoading, iataCode: null, uicCode: null },
        destination: { unLocode: searchCriteria.portOfDischarge, iataCode: null, uicCode: null },
      },
      transportModeRequests: ["ocean"]
    }
    console.log('CFC request: ', returnValue)

    fetchRecords.execute(
      carbonFootprintApiUrls.CALCULATE_STANDARD,
      { customData: returnValue },
      (data) => {
        console.log('CFC results: ', data)
        let totalC02 = data.listValueChartTransportResponse.co2EquivalentsTotal
        let totalC02Emi = totalC02.airValue[0] + totalC02.bargeValue[0] + totalC02.oceanValue[0] + totalC02.trainValue[0] + totalC02.truckValue[0]
        let totalEnergy = data.listValueChartTransportResponse.energyConsumptionTotal
        let totalEnergyCon = totalEnergy.airValue[0] + totalEnergy.bargeValue[0] + totalEnergy.oceanValue[0] + totalEnergy.trainValue[0] + totalEnergy.truckValue[0]
        setCfcResults([totalC02Emi.toFixed(3), totalEnergyCon.toFixed(3)])
      },
      (error) => {
        console.log(error)
        setLoading(true)
      }
    )

  }, [])

  useEffect(() => {
    if (pollingCount !== 0) {
      setTimeout(() => {
        fetchRecords.execute(
          searchScheduleApiUrls.SHIPPER_VESSEL_GET_POLLING,
          {
            customData: { pollingId: pollingProgress.current[2] }
          },
          (data) => {
            const { totalSource, processedSource, pollingId } = data
            pollingProgress.current = [totalSource, processedSource, pollingId]
            if (
              pollingProgress.current[0] !== 0 &&
              pollingProgress.current[1] !== pollingProgress.current[0]
            ) {
              setPollingCount(pollingCount + 1)
            } else {
              setPollingCount(0)
              if (shipperSchedules.length > 0 || runFilter.current) {
                //setConfirmDialogOpen(true)
                console.log('Setting autoRefresh in localStorage to false')
                localStorage.setItem('autoRefresh', JSON.stringify(false))
                setShowRefreshButton(true)
              } else {
                setPollingId(pollingProgress.current[2])
                //setConfirmDialogOpen(false)
                if (initialShipperSchedulesLength === 0) {
                  console.log('Setting autoRefresh in localStorage to true')
                  localStorage.setItem('autoRefresh', JSON.stringify(true))
                  submitForm()
                } else {
                  console.log('Setting autoRefresh in localStorage to false')
                  localStorage.setItem('autoRefresh', JSON.stringify(false))
                  setShowRefreshButton(true)
                }
              }
            }
          },
          (error) => {
            console.log(error)
            setPollingCount(pollingCount + 1)
          }
        )
      }, 1000)
    }
  }, [pollingCount])

  const ConnectButton = withStyles((theme) => ({
    root: {
      color: '#ffffff',
      fontWeight: 'bold',
      fontSize: '9px',
      backgroundColor: '#7CE7AC',
      '&:hover': {
        backgroundColor: '#7CE7AC'
      }
    }
  }))(Button)

  const expandInfo = (id, startSeqNo, endSeqNo) => () => {
    if (voyageLegs.findIndex((voyageLeg) => voyageLeg.voyageId === id) === -1) {
      fetchRecords.execute(
        searchScheduleApiUrls.SHIPPER_VESSEL_SCHEDULE_LEG,
        {
          customData: {
            voyageId: id,
            startSeqNo: startSeqNo,
            endSeqNo: endSeqNo
          }
        },
        (data) => {
          let FromLegApi = data
          let UniqueVSScheduleSet = new Set(
            data.content
              .filter((item) => {
                if (item.transportMode === null) {
                  return false
                } else {
                  return true
                }
              })
              .map(
                (item) =>
                  item.voyageId +
                  item.schVoyageNo +
                  item.schVesselName +
                  item.transportMode
              )
          )

          let check = [...Array.from(UniqueVSScheduleSet.values())].map((v) => {
            return {
              key: v,
              voyageId: id,
              endSeq: getMaxSeqNo(data.content, v),
              startSeq: getMinSeqNo(data.content, v)
            }
          })

          data['check'] = check
          console.log('check account', data)
          setVoyageLegs([...voyageLegs, data])
        },
        (error) => {
          console.log(error)
        }
      )
    }

    const newSetShipperSchedules = [...shipperSchedules]
    const index = shipperSchedules.findIndex((row) => row.voyageId === id)
    const row = shipperSchedules[index]
    newSetShipperSchedules[index] = { ...row, expand: !row.expand }
    setShipperSchedules(newSetShipperSchedules)
  }

  function getMaxSeqNo(legs, key) {
    return Math.max.apply(
      Math,
      legs
        .filter(
          (item) =>
            (item.transportMode === null ||
              item.voyageId +
                item.schVoyageNo +
                item.schVesselName +
                item.transportMode ===
                key) &&
            item.listOut
        )
        .map((o) => o.seqNo)
    )
  }

  function getMinSeqNo(legs, key) {
    return Math.min.apply(
      Math,
      legs
        .filter(
          (item) =>
            item.voyageId +
              item.schVoyageNo +
              item.schVesselName +
              item.transportMode ===
              key && item.listOut
        )
        .map((o) => o.seqNo)
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const comparator =
    (prop, desc = true) =>
    (a, b) => {
      if (a[prop] === null) {
        return 1
      }
      if (b[prop] === null) {
        return -1
      }
      const order = desc ? -1 : 1
      if (a[prop] < b[prop]) {
        return -1 * order
      }
      if (a[prop] > b[prop]) {
        return 1 * order
      }
      return 0 * order
    }

  const onSortClick = (fieldName) => () => {
    let newOrder =
      columns.find((column) => column.sortfieldName === fieldName).order ===
      'desc'
        ? 'asc'
        : 'desc'

    setColumns(
      columns.map((column) => ({
        ...column,
        active: fieldName === column.sortfieldName,
        order:
          (fieldName === column.sortfieldName &&
            (column.order === 'desc' ? 'asc' : 'desc')) ||
          undefined
      }))
    )

    fieldName === 'podEta' && newOrder === 'desc'
      ? setEarlierClick(true)
      : setEarlierClick(false)

    fieldName === 'transit' && newOrder === 'desc'
      ? setFasterClick(true)
      : setFasterClick(false)

    setShipperSchedules(
      shipperSchedules
        .slice()
        .sort(
          comparator(
            fieldName,
            columns.find((column) => column.sortfieldName === fieldName)
              .order === 'desc'
          )
        )
    )
  }

  const bookFromSchedule = (schedule) => () => {
    console.log(schedule)
    console.log(searchCriteria)
    console.log(portRef.current)

    //go to create new booking page

    //port desc will be fetched from booking page as portRef is no longer containing port desc
    // let polDesc =
    //   portRef.current.findIndex((port) => port.code === schedule.pol) !== -1
    //     ? portRef.current[
    //       portRef.current.findIndex((port) => port.code === schedule.pol)
    //     ].descriptionEn
    //     : schedule.pol

    // console.log(portRef.current.findIndex((port) => port.code === schedule.pol) !== -1)

    // console.log(polDesc)
    // let podDesc =
    //   portRef.current.findIndex((port) => port.code === schedule.pod) !== -1
    //     ? portRef.current[
    //       portRef.current.findIndex((port) => port.code === schedule.pod)
    //     ].descriptionEn
    //     : schedule.pod

    //map some fields
    schedule.polCode = searchCriteria.portOfLoading
    schedule.podCode = searchCriteria.portOfDischarge
    schedule.placeOfReceiptCode = searchCriteria.portOfLoading
    schedule.placeOfDeliveryCode = searchCriteria.portOfDischarge
    schedule.cargoReadyDate = searchCriteria.cargoReadyDate
    schedule.cargoDeliveryDate = searchCriteria.rangeOfCargoReadyDate
    schedule.placeOfReceiptEtd = schedule.polEtd
    schedule.placeOfDeliveryEta = schedule.podEta
    schedule.portOfLoadingTerminal = schedule.polTerminal
    schedule.portOfDischargeTerminal = schedule.podTerminal
    schedule.intVoyageNo = schedule.internationalVoyageNumber
    // schedule.placeOfReceipt = polDesc
    // schedule.placeOfDelivery = podDesc
    schedule.bookFromSchedule = true

    history.push({
      //pathname: '/cal-freightbooking/my-booking/edit',
      pathname: pathMap.CREATE_NEW_BOOKING,
      state: { booking: schedule }
    })
  }

  const handleDirectCheckBoxChange = (newValue) => {
    setDirectOnly(newValue)
    performFilter(newValue, carriers, transitTime, cyCutOffRange)
  }

  const onCarrierCheckBoxChange =
    (index) =>
    ({ target: { checked } }) => {
      let newCarriers = [...carriers]
      const carrier = carriers[index]
      if (carrier.name !== 'ALL') {
        const allCarrier = carriers[carriers.length - 1]

        newCarriers[index] = { ...carrier, checked: !carrier.checked }
        newCarriers[carriers.length - 1] = { ...allCarrier, checked: false }
      } else {
        newCarriers = newCarriers.map((item) => ({
          ...item,
          checked: !carrier.checked
        }))
      }

      setCarriers(newCarriers)

      performFilter(directOnly, newCarriers, transitTime, cyCutOffRange)
    }

  const handleTransitTimeSilderMoveChange = (event, newValue) => {
    let newTransitTime = [
      {
        value: newValue[0],
        label: sortedTransitTimeArray.current.find(
          (item) => item.value === newValue[0]
        ).label
      },
      {
        value: newValue[1],
        label: sortedTransitTimeArray.current.find(
          (item) => item.value === newValue[1]
        ).label
      }
    ]
    setTransitTime(newTransitTime)
  }

  const handleTransitTimeSliderCommittedChange = () => {
    performFilter(directOnly, carriers, transitTime, cyCutOffRange)
  }

  const handleCyCutOffSliderMoveChange = (event, newValue) => {
    let newCyCutOffRange = [
      {
        value: newValue[0],
        label: sortedCyCutOffArray.current.find(
          (item) => item.value === newValue[0]
        ).label
      },
      {
        value: newValue[1],
        label: sortedCyCutOffArray.current.find(
          (item) => item.value === newValue[1]
        ).label
      }
    ]
    setCyCutOffRange(newCyCutOffRange)
  }

  const handleCyCutOffSliderCommittedChange = () => {
    performFilter(directOnly, carriers, transitTime, cyCutOffRange)
  }

  const performFilter = (
    directOnly,
    newCarriers,
    newTransitTime,
    newCyCutOffRange
  ) => {
    runFilter.current = true
    setLoading(true)
    setEarlierClick(true)
    setFasterClick(false)
    setColumns(
      columns.map((column) => ({
        ...column,
        active: column.sortfieldName === 'podEta',
        order: column.sortfieldName === 'podEta' ? 'desc' : undefined
      }))
    )
    setPage(0)
    const newShipperSchedules = [...originalShipperSchedules]
    setTimeout(() => {
      setShipperSchedules(
        newShipperSchedules
          .filter((schedule) => {
            if (directOnly) {
              return schedule.directShipment
            } else {
              return true
            }
          })
          .filter((schedule) => {
            let item =
              newCarriers &&
              newCarriers.find(
                (newCarrier) =>
                  newCarrier.name === schedule.carrierName && newCarrier.checked
              )
            return item === undefined ? false : true
          })
          .filter(
            (schedule) =>
              schedule.transit >= newTransitTime[0].label &&
              schedule.transit <= newTransitTime[1].label
          )
          .filter((schedule) => {
            if (
              newCyCutOffRange &&
              !moment(schedule.cyCutoff, moment.ISO_8601, true).isValid()
            ) {
              return true
            } else {
              return (
                moment(schedule.cyCutoff).isAfter(
                  moment(newCyCutOffRange[0].label)
                ) &&
                moment(schedule.cyCutoff).isBefore(
                  moment(newCyCutOffRange[1].label).add(1, 'days')
                )
              )
            }
          })
      )
      setLoading(false)
    }, 500)
  }

  const handleClearAllButtonClick = () => {
    setDirectOnly(false)
    // setPreferred('DEFAULT')
    let newCarriers = [...carriers]
    newCarriers = newCarriers.map((item) => ({
      ...item,
      checked: true
    }))
    setTransitTime(minMaxValue)
    setCyCutOffRange(minMaxDateValue)
    setCarriers(newCarriers)

    performFilter(false, newCarriers, minMaxValue, minMaxDateValue)
  }

  const refreshData = () => {
    setPollingId(pollingProgress.current[2])
    setConfirmDialogOpen(false)
    submitForm()
  }

  const handleOnSearch = () => {
    if (searchCriteriaNotChange()) {
      setPollingId(pollingProgress.current[2])
    }

    submitForm()
  }

  function searchCriteriaNotChange() {
    return (
      weeksField.value === location.state.weeks &&
      fromField.value === location.state.from &&
      toField.value === location.state.to &&
      departDatefield.value === location.state.departDate
    )
  }

  return (
    <Grid container spacing={1}>
      <SearchPanel
        carbonFootprintResults={cfcResults}
        searchPortFrom={location.state.from}
        searchPortTo={location.state.to}
        searchArrivalDate={location.state.arrivalDate}
        searchDepartDate={location.state.departDate}
        searchPreferred={location.state.preferred}
        searchToCountryCode={location.state.toCountryCode}
        searchFromCountryCode={location.state.fromCountryCode}
        weeks={location.state.weeks}
        resultPage
        handleOnSearch={handleOnSearch}
      />
      <CngGridItem
        xs={12}
        sm={9}
        shouldHide={
          !loading ? (shipperSchedules.length > 0 ? true : false) : true
        }
      >
        <PollingProgressBar
          pollingCount={pollingCount}
          pollingProgress={pollingProgress}
          pollingId={location.state.pollingId}
          refreshData={refreshData}
          hideInfoBar={true}
        />
        {runFilter.current || typeof location.state.pollingId === 'number' ? (
          <NoResult
            showNotification={showNotification}
            runFilter={runFilter.current}
            disableButton={false}
            handleClearAllButtonClick={handleClearAllButtonClick}
          />
        ) : (
          <NoResultProgressBar />
        )}
      </CngGridItem>
      <CngGridItem
        xs={12}
        sm={9}
        shouldHide={!loading && shipperSchedules.length > 0 ? false : true}
      >
        <Card>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <div className={'rs-align-spacing-grey'}>
                <Typography variant='caption'>{`Results ${Math.min(
                  page * rowsPerPage + 1,
                  shipperSchedules.length
                )}-${Math.min(
                  (page + 1) * rowsPerPage,
                  shipperSchedules.length
                )} of ${shipperSchedules.length}`}</Typography>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={'rs-align-spacing'}>
                {showRefreshButton && ( // Conditionally render Refresh button when showRefreshButton is true
                  <CngPrimaryButton size='small' onClick={refreshData}>
                    {translatedTextsObject.refresh}
                  </CngPrimaryButton>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                spacing={2}
                justify='flex-end'
                alignItems='flex-start'
              >
                <Grid item>
                  <div className={'rs-align-spacing'}>
                    <Button
                      variant={fasterClick ? 'outlined' : 'text'}
                      onClick={onSortClick('transit')}
                    >
                      {translatedTextsObject.fastest}
                    </Button>
                  </div>
                </Grid>
                <Grid item>
                  <div className={'rs-align-spacing'}>
                    <Button
                      variant={earlierClick ? 'outlined' : 'text'}
                      onClick={onSortClick('podEta')}
                    >
                      {translatedTextsObject.earliest}
                    </Button>
                  </div>
                </Grid>
                <CngGridItem
                  shouldHide={
                    pollingProgress.current[0] !== pollingProgress.current[1]
                  }
                >
                  <div className={'rs-align-spacing'}>
                    <ExpandMenus
                      shipperSchedules={shipperSchedules}
                      showNotification={showNotification}
                    />
                  </div>
                </CngGridItem>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={column.name + index}
                      className={'vs-table-cell-padding'}
                    >
                      <TableSortLabel
                        active={column.active}
                        direction={column.order}
                        onClick={onSortClick(column.sortfieldName)}
                      >
                        <div className={'rs-grey'}>{column.name}</div>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell className={'vs-table-cell-padding'}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0 && carriers.length > 0
                  ? shipperSchedules.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : shipperSchedules
                ).map((shipperSchedule) => (
                  <Fragment key={`Fragment-${shipperSchedule.voyageId}`}>
                    <TableRow key={shipperSchedule.voyageId}>
                      <TableCell
                        className={'vs-table-cell-padding'}
                        component='th'
                        scope='row'
                      >
                        <HtmlTooltip
                          title={shipperSchedule.carrierFullName}
                          placement='top'
                        >
                          <div>
                            <div>{shipperSchedule.carrierName}</div>

                            <div>
                              <Avatar
                                classes={{
                                  img: 'vs-carrier-image'
                                }}
                                variant='rounded'
                                src={`${process.env.PUBLIC_URL}/static/images/carriers/${shipperSchedule.carrierName}.svg`}
                              >
                                <img
                                  className={'search-button'}
                                  src={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
                                ></img>
                              </Avatar>
                            </div>
                          </div>
                        </HtmlTooltip>
                      </TableCell>
                      <TableCell className={'vs-table-cell-padding'}>
                        <div className={'rs-showContent-bold'}>
                          {shipperSchedule.vesselName}
                        </div>
                        <div className={'rs-showContent-bold'}>
                          {shipperSchedule.service}
                        </div>
                      </TableCell>
                      <TableCell className={'vs-table-cell-padding'}>
                        <div className={'rs-showContent-bold'}>
                          {shipperSchedule.transit} {translatedTextsObject.days}
                        </div>
                        {shipperSchedule.directShipment ? (
                          <div className={'rs-green'}>
                            {' '}
                            {translatedTextsObject.direct}
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell className={'vs-table-cell-padding'}>
                        <div className={'rs-showContent-bold'}>
                          {moment(
                            shipperSchedule.cyCutoff,
                            moment.ISO_8601,
                            true
                          ).isValid()
                            ? moment(shipperSchedule.cyCutoff).format(
                                'D MMM (ddd) HH:mm'
                              )
                            : shipperSchedule.cyCutoff}
                        </div>
                      </TableCell>
                      <TableCell className={'vs-table-cell-padding'}>
                        <div className={'rs-showContent-bold'}>
                          {moment(shipperSchedule.polEtd).format(
                            'D MMM (ddd) HH:mm'
                          )}
                        </div>
                        <div className={'vs-result-content-down'}>
                          <span>
                            <Chip
                              label={<b>{shipperSchedule.pol}</b>}
                              size='small'
                            />
                          </span>
                        </div>
                      </TableCell>
                      <TableCell className={'vs-table-cell-padding'}>
                        <div className={'rs-showContent-bold'}>
                          {moment(shipperSchedule.podEta).format(
                            'D MMM (ddd) HH:mm'
                          )}
                        </div>
                        <div className={'vs-result-content-down'}>
                          <span>
                            <Chip
                              label={<b>{shipperSchedule.pod}</b>}
                              size='small'
                            />
                          </span>
                        </div>
                      </TableCell>
                      <TableCell
                        align='right'
                        className={'vs-table-cell-padding'}
                      >
                        <Box display='flex' justifyContent='flex-end'>
                          {shipperSchedule.transportMode !== null &&
                          (shipperSchedule.transportMode.includes('V') ||
                            shipperSchedule.transportMode.includes('F') ||
                            shipperSchedule.transportMode.includes('B')) ? (
                            <Box mr={2} mt={1}>
                              <DirectionsBoatOutlinedIcon color='disabled' />
                            </Box>
                          ) : null}
                          {shipperSchedule.transportMode !== null &&
                          (shipperSchedule.transportMode.includes('R') ||
                            shipperSchedule.transportMode.includes('T') ||
                            shipperSchedule.transportMode.includes('L')) ? (
                            <Box mr={2} mt={1}>
                              <TrainOutlinedIcon color='disabled' />
                            </Box>
                          ) : null}
                          {shipperSchedule.transportMode !== null &&
                          shipperSchedule.transportMode.includes('A') ? (
                            <Box mr={2} mt={1}>
                              <FlightIcon color='disabled' />
                            </Box>
                          ) : null}

                          {shipperSchedule.connected ? (
                            <CngSecondaryButton
                              style={{ width: '90px' }}
                              name='book'
                              startIcon={<AddCircleIcon />}
                              onClick={bookFromSchedule(shipperSchedule)}
                            >
                              {translatedTextsObject.book}
                            </CngSecondaryButton>
                          ) : (
                            <ConnectButton
                              variant='contained'
                              color='primary'
                              style={{ width: '90px' }}
                              onClick={() => {
                                setConnectCarrierPartyId(
                                  shipperSchedule.carrierPartyId
                                )
                                setManageCarrierConnectionDialogOpen(true)
                              }}
                            >
                              {translatedTextsObject.connectCarrier}
                            </ConnectButton>
                          )}
                          <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={expandInfo(
                              shipperSchedule.voyageId,
                              shipperSchedule.startSeqNo,
                              shipperSchedule.endSeqNo
                            )}
                          >
                            {shipperSchedule.expand ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={7}
                      >
                        <Collapse
                          in={shipperSchedule.expand}
                          timeout='auto'
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <Table size='small' aria-label='purchases'>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <div className={'rs-grey'}>
                                      {translatedTextsObject.transportVia}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className={'rs-grey'}>
                                      {translatedTextsObject.cyCutOff}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className={'rs-grey'}>
                                      {translatedTextsObject.arrive}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className={'rs-grey'}>
                                      {translatedTextsObject.departure}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className={'rs-grey'}>
                                      {translatedTextsObject.voyagerDetails}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className={'rs-grey'}>
                                      {translatedTextsObject.terminal.toUpperCase()}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {voyageLegs
                                  .filter(
                                    (voyageLeg) =>
                                      voyageLeg.voyageId ===
                                      shipperSchedule.voyageId
                                  )
                                  .map((item) =>
                                    item.content
                                      .filter((leg) => {
                                        return (
                                          leg.transportMode === null ||
                                          item.check[
                                            item.check.findIndex(
                                              (c) =>
                                                c.key ===
                                                leg.voyageId +
                                                  leg.schVoyageNo +
                                                  leg.schVesselName +
                                                  leg.transportMode
                                            )
                                          ].endSeq === leg.seqNo ||
                                          item.check[
                                            item.check.findIndex(
                                              (c) =>
                                                c.key ===
                                                leg.voyageId +
                                                  leg.schVoyageNo +
                                                  leg.schVesselName +
                                                  leg.transportMode
                                            )
                                          ].startSeq === leg.seqNo
                                        )
                                      })
                                      .map((leg) =>
                                        leg.listOut ? (
                                          <TableRow
                                            key={`${leg.voyageId}_${leg.seqNo}`}
                                          >
                                            <TableCell>
                                              <div
                                                className={
                                                  'rs-showContent-bold'
                                                }
                                              >
                                                <PortCountryViewField
                                                  countryList={country}
                                                  portCode={leg.portCode}
                                                />
                                              </div>
                                            </TableCell>
                                            <TableCell>
                                              <div
                                                className={
                                                  'rs-showContent-bold'
                                                }
                                              >
                                                {moment(
                                                  leg.cyCutoffTime,
                                                  moment.ISO_8601,
                                                  true
                                                ).isValid()
                                                  ? moment(
                                                      leg.cyCutoffTime
                                                    ).format(
                                                      'D MMM (ddd) HH:mm'
                                                    )
                                                  : leg.cyCutoffTime}
                                              </div>
                                            </TableCell>
                                            <TableCell>
                                              <div
                                                className={
                                                  'rs-showContent-bold'
                                                }
                                              >
                                                {item.startSeqNo === leg.seqNo
                                                  ? null
                                                  : moment(
                                                      leg.arrivalTime
                                                    ).format(
                                                      'D MMM (ddd) HH:mm'
                                                    )}
                                              </div>
                                            </TableCell>
                                            <TableCell>
                                              <div
                                                className={
                                                  'rs-showContent-bold'
                                                }
                                              >
                                                {item.endSeqNo === leg.seqNo
                                                  ? null
                                                  : moment(
                                                      leg.departureTime
                                                    ).format(
                                                      'D MMM (ddd) HH:mm'
                                                    )}
                                              </div>
                                            </TableCell>

                                            <TableCell>
                                              <div
                                                className={
                                                  'rs-showContent-bold'
                                                }
                                              >
                                                {leg.transportMode === 'SEA' ||
                                                leg.transportMode ===
                                                  'FEEDER' ||
                                                leg.transportMode === 'WATER' ||
                                                leg.transportMode === 'BARGE'
                                                  ? leg.schVesselName ===
                                                      null &&
                                                    leg.schVoyageNo === null
                                                    ? item.endSeqNo ===
                                                      leg.seqNo
                                                      ? ''
                                                      : item.check.find(
                                                          (v) =>
                                                            v.key ===
                                                            leg.voyageId +
                                                              leg.schVoyageNo +
                                                              leg.schVesselName +
                                                              leg.transportMode
                                                        ).startSeq === leg.seqNo
                                                      ? shipperSchedule.vesselName +
                                                        ' ' +
                                                        shipperSchedule.voyageNo
                                                      : leg.transportMode !==
                                                        null
                                                      ? leg.transportMode +
                                                        ' ' +
                                                        manageScheduleTranslationText.transport.toUpperCase()
                                                      : ''
                                                    : leg.schVesselName +
                                                      ' ' +
                                                      (leg.schVoyageNo === null
                                                        ? ''
                                                        : leg.schVoyageNo)
                                                  : leg.transportMode !== null
                                                  ? leg.transportMode +
                                                    ' ' +
                                                    manageScheduleTranslationText.transport.toUpperCase()
                                                  : ''}
                                              </div>
                                            </TableCell>
                                            <TableCell>
                                              <div
                                                className={
                                                  'rs-showContent-bold'
                                                }
                                              >
                                                <TerminalInitial
                                                  withTooltip={
                                                    leg.terminal
                                                      ? leg.terminal.length > 15
                                                      : false
                                                  }
                                                  longName={leg.terminal}
                                                  shortName={
                                                    leg.terminal
                                                      ? leg.terminal.length > 15
                                                        ? leg.terminal
                                                            .substring(0, 15)
                                                            .trim() + '...'
                                                        : leg.terminal
                                                      : ''
                                                  }
                                                />
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        ) : null
                                      )
                                  )}

                                <TableRow>
                                  <TableCell colSpan={5}>
                                    <PortsStepper
                                      voyageLegs={voyageLegs}
                                      shipperScheduleVoyageId={
                                        shipperSchedule.voyageId
                                      }
                                      shipperScheduleDirectShipment={
                                        shipperSchedule.directShipment
                                      }
                                      countryList={country}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <ResultPageTablePagination
                    recordLength={shipperSchedules.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Card>
      </CngGridItem>
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <NoResultProgressBar />
      </CngGridItem>
      <CngGridItem xs={12} sm={3} shouldHide={false}>
        <FilterPanel
          handleClearAllButtonClick={handleClearAllButtonClick}
          preferred={location.state.preferred}
          handleDirectCheckBoxChange={handleDirectCheckBoxChange}
          directOnly={directOnly}
          directOnlyCount={directOnlyCount.current}
          transitTime={transitTime}
          carriers={carriers}
          cyCutOffRange={cyCutOffRange}
          onCarrierCheckBoxChange={onCarrierCheckBoxChange}
          handleTransitTimeSliderCommittedChange={
            handleTransitTimeSliderCommittedChange
          }
          handleTransitTimeSliderMoveChange={handleTransitTimeSilderMoveChange}
          handleCyCutOffSliderCommittedChange={
            handleCyCutOffSliderCommittedChange
          }
          handleCyCutOffSliderMoveChange={handleCyCutOffSliderMoveChange}
          minMaxValue={minMaxValue}
          minMaxDateValue={minMaxDateValue}
          carrier={false}
        />
      </CngGridItem>
      <ManageCarrierConnectionDialog
        showNotification={showNotification}
        isDialogOpen={isManageCarrierConnectionDialogOpen}
        closeDialog={() => setManageCarrierConnectionDialogOpen(false)}
        connectCarrierPartyId={connectCarrierPartyId}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen.value}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={refreshData}
        content={translatedTextsObject.dialogContent}
        okMsg={translatedTextsObject.dialogOK}
        cancelMsg={translatedTextsObject.dialogCancel}
        title={translatedTextsObject.dialogTitle}
      />
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData)
  localData.createdDate = DateTimeFormatter.toClientDate(localData.createdDate)
  localData.updatedDate = DateTimeFormatter.toClientDate(localData.updatedDate)
  return localData
}

function toServerDataFormat(localData) {
  localData.createdDate = DateTimeFormatter.toServerDate(localData.createdDate)
  localData.updatedDate = DateTimeFormatter.toServerDate(localData.updatedDate)
  return DataFlattener.unflatten(localData)
}

const FormPropertiesResult = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormPropertiesResult
