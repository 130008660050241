import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import {
  DataFlattener,
  DateTimeFormatter,
  components,
  constants,
  useServices
} from 'cng-web-lib'
import { Fragment, useEffect, useState } from 'react'
import React, { useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import FilterPanel from 'src/views/vesselschedule/searchschedule/FilterPanel'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ManageCarrierConnectionDialog from 'src/views/vesselschedule/searchschedule/managecarrierconnection/ManageCarrierConnectionDialog.js'
import ManageScheduleTranslationText from 'src/views/vesselschedule/manageschedule/ManageScheduleTranslationText'
import MiscComponent from './MiscComponent'
import NoResult from 'src/views/vesselschedule/searchschedule/NoResult'
import PortCountryViewField from 'src/components/field/PortCountryViewField'
import PortsStepper from 'src/views/vesselschedule/searchschedule/PortsStepper'
import ResultPageTablePagination from 'src/views/vesselschedule/searchschedule/ResultPageTablePagination'
import SearchPanel from 'src/views/vesselschedule/searchschedule/SearchPanel'
import SearchScheduleTranslationText from 'src/views/vesselschedule/searchschedule/SearchScheduleTranslationText'
import TcalVsManageScheduleApiUrls from 'src/apiUrls/TcalVsManageScheduleApiUrls.js'
import TcalVsVesselApiUrls from 'src/apiUrls/TcalVsVesselApiUrls.js'
import TerminalInitial from '../../component/TerminalInitial'
import makeValidationSchema from 'src/views/vesselschedule/searchschedule/MakeValidationSchema'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import searchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls'
import { withStyles } from '@material-ui/core/styles'

const {
  button: { CngSecondaryButton },
  CngGridItem
} = components

const {
  CodeMaintenanceType,
  filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  departDate: DateTimeFormatter.toClientDate(new Date()),
  arrivalDate: DateTimeFormatter.toClientDate(
    new Date(
      new Date().setTime(new Date().getTime() + 4 * 7 * 24 * 60 * 60 * 1000)
    )
  ),
  from: '',
  to: '',
  fromCountryCode: '',
  toCountryCode: '',
  pollingId: '',
  weeks: 4,
  carrierSearch: true
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const transportMode = new Map([
  ['R', 'RAIL'],
  ['L', 'RAIL'],
  ['T', 'TRUCK'],
  ['V', 'VOYAGE'],
  ['B', 'VOYAGE'],
  ['F', 'FEEDER'],
  ['A', 'AIR']
])

function Fields({ disabled, showNotification, shouldHideMap }) {
  const translatedTextsObject = SearchScheduleTranslationText()
  const manageScheduleTranslationText = ManageScheduleTranslationText()
  const { fetchRecords } = useServices()
  const [shipperSchedules, setShipperSchedules] = useState([])
  const [originalShipperSchedules, setOriginalshipperSchedules] = useState([])
  const [voyageLegs, setVoyageLegs] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [earlierClick, setEarlierClick] = useState(true)
  const [fasterClick, setFasterClick] = useState(false)
  const portRef = useRef([])
  const history = useHistory()
  const location = useLocation()
  const [transitTime, setTransitTime] = useState([])
  const [minMaxValue, setMinMaxValue] = useState([])
  const [cyCutOffRange, setCyCutOffRange] = useState([])
  const [minMaxDateValue, setMinMaxDateValue] = useState([])
  const sortedCyCutOffArray = useRef([])
  const sortedTransitTimeArray = useRef([])
  const runFilter = useRef(false)
  const [directOnly, setDirectOnly] = useState(false)
  const directOnlyCount = useRef(0)
  const [connectCarrierPartyId, setConnectCarrierPartyId] = useState()
  const [country, setCountry] = useState([])
  const [
    isManageCarrierConnectionDialogOpen,
    setManageCarrierConnectionDialogOpen
  ] = useState(false)
  const [columns, setColumns] = useState([
    {
      name: `${translatedTextsObject.vessel}/ ${translatedTextsObject.service}`,
      active: false,
      sortfieldName: 'vesselName'
    },
    {
      name: translatedTextsObject.transitTime,
      active: false,
      sortfieldName: 'transit'
    },
    {
      name: translatedTextsObject.cyCutOff,
      active: false,
      sortfieldName: 'cyCutoff'
    },
    {
      name: translatedTextsObject.departPOL,
      active: false,
      sortfieldName: 'polEta'
    },
    {
      name: translatedTextsObject.arrivePOD,
      active: true,
      sortfieldName: 'podEta',
      order: 'desc'
    },
    {
      name: translatedTextsObject.voyageStatus.toUpperCase(),
      active: false,
      sortfieldName: ''
    }
  ])

  let searchCriteria = {
    cargoReadyDate: location.state.departDate,
    rangeOfCargoReadyDate: location.state.arrivalDate,
    portOfLoading: location.state.from,
    portOfDischarge: location.state.to,
    weeks: location.state.weeks,
    carrierSearch: true
  }

  useEffect(() => {
    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.COUNTRY,
      [],
      false,
      onLoadCountrySuccess
    )

    function onLoadCountrySuccess(data) {
      setCountry(data)
    }

    fetchRecords.execute(
      searchScheduleApiUrls.CARRIER_VESSEL_SCHEDULE,
      {
        customData: searchCriteria
      },
      (data) => {
        let FromApi = data.content.map((v) => ({ ...v, expand: false }))
        setShipperSchedules(FromApi)
        setOriginalshipperSchedules(FromApi)
        setLoading(false)
        let TransitTimeSet = new Set(FromApi.map((item) => item.transit))
        let cyCutOffDateSet = new Set(
          FromApi.filter((item) =>
            moment(item.cyCutoff, moment.ISO_8601, true).isValid()
          ).map((item) => moment(item.cyCutoff).format('YYYY-MM-DD'))
        )

        directOnlyCount.current = FromApi.filter(
          (item) => item.directShipment
        ).length

        if (cyCutOffDateSet.size > 0) {
          const sortedArray = [...Array.from(cyCutOffDateSet.values())]
            .slice()
            .sort()
          sortedCyCutOffArray.current = sortedArray.map((item, index) => ({
            value: index,
            label: moment(item).format('D MMM YYYY')
          }))
          console.log(sortedCyCutOffArray.current)
          setCyCutOffRange([
            {
              value: sortedCyCutOffArray.current[0].value,
              label: sortedCyCutOffArray.current[0].label
            },
            {
              value:
                sortedCyCutOffArray.current[
                  sortedCyCutOffArray.current.length - 1
                ].value,
              label:
                sortedCyCutOffArray.current[
                  sortedCyCutOffArray.current.length - 1
                ].label
            }
          ])
          setMinMaxDateValue([
            {
              value: sortedCyCutOffArray.current[0].value,
              label: sortedCyCutOffArray.current[0].label
            },
            {
              value:
                sortedCyCutOffArray.current[
                  sortedCyCutOffArray.current.length - 1
                ].value,
              label:
                sortedCyCutOffArray.current[
                  sortedCyCutOffArray.current.length - 1
                ].label
            }
          ])
        }

        if (TransitTimeSet.size > 0) {
          sortedTransitTimeArray.current = [
            ...Array.from(TransitTimeSet.values())
          ]
            .slice()
            .sort(
              (a, b) =>
                (typeof b === 'number') - (typeof a === 'number') ||
                (a > b ? 1 : -1)
            )
            .map((item, index) => ({
              value: index,
              label: item
            }))
          setTransitTime([
            {
              value: sortedTransitTimeArray.current[0].value,
              label: sortedTransitTimeArray.current[0].label
            },
            {
              value:
                sortedTransitTimeArray.current[
                  sortedTransitTimeArray.current.length - 1
                ].value,
              label:
                sortedTransitTimeArray.current[
                  sortedTransitTimeArray.current.length - 1
                ].label
            }
          ])
          setMinMaxValue([
            {
              value: sortedTransitTimeArray.current[0].value,
              label: sortedTransitTimeArray.current[0].label
            },
            {
              value:
                sortedTransitTimeArray.current[
                  sortedTransitTimeArray.current.length - 1
                ].value,
              label:
                sortedTransitTimeArray.current[
                  sortedTransitTimeArray.current.length - 1
                ].label
            }
          ])
        }
      },
      (error) => {
        console.log(error)
        setLoading(false)
      }
    )
  }, [])

  const ConnectButton = withStyles((theme) => ({
    root: {
      color: '#ffffff',
      fontWeight: 'bold',
      fontSize: '9px',
      backgroundColor: '#7CE7AC',
      '&:hover': {
        backgroundColor: '#7CE7AC'
      }
    }
  }))(Button)

  const expandInfo = (id, startSeqNo, endSeqNo) => () => {
    if (voyageLegs.findIndex((voyageLeg) => voyageLeg.voyageId === id) === -1) {
      fetchRecords.execute(
        searchScheduleApiUrls.CARRIER_VESSEL_SCHEDULE_LEG,
        {
          customData: {
            voyageId: id,
            startSeqNo: startSeqNo,
            endSeqNo: endSeqNo
          }
        },
        (data) => {
          let FromLegApi = data
          let UniqueVSScheduleSet = new Set(
            data.content
              .filter((item) => {
                if (item.transportMode === null) {
                  return false
                } else {
                  return true
                }
              })
              .map(
                (item) =>
                  item.voyageId +
                  item.schVoyageNo +
                  item.schVesselName +
                  item.transportMode
              )
          )

          let check = [...Array.from(UniqueVSScheduleSet.values())].map((v) => {
            return {
              key: v,
              voyageId: id,
              endSeq: getMaxSeqNo(data.content, v),
              startSeq: getMinSeqNo(data.content, v)
            }
          })

          data['check'] = check
          setVoyageLegs([...voyageLegs, data])
        },
        (error) => {
          console.log(error)
        }
      )
    }

    const newSetShipperSchedules = [...shipperSchedules]
    const index = shipperSchedules.findIndex((row) => row.voyageId === id)
    const row = shipperSchedules[index]
    newSetShipperSchedules[index] = { ...row, expand: !row.expand }
    setShipperSchedules(newSetShipperSchedules)
  }

  function getMaxSeqNo(legs, key) {
    return Math.max.apply(
      Math,
      legs
        .filter(
          (item) =>
            (item.transportMode === null ||
              item.voyageId +
                item.schVoyageNo +
                item.schVesselName +
                item.transportMode ===
                key) &&
            item.listOut
        )
        .map((o) => o.seqNo)
    )
  }

  function getMinSeqNo(legs, key) {
    return Math.min.apply(
      Math,
      legs
        .filter(
          (item) =>
            item.voyageId +
              item.schVoyageNo +
              item.schVesselName +
              item.transportMode ===
              key && item.listOut
        )
        .map((o) => o.seqNo)
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const comparator =
    (prop, desc = true) =>
    (a, b) => {
      if (a[prop] === null) {
        return 1
      }
      if (b[prop] === null) {
        return -1
      }
      const order = desc ? -1 : 1
      if (a[prop] < b[prop]) {
        return -1 * order
      }
      if (a[prop] > b[prop]) {
        return 1 * order
      }
      return 0 * order
    }

  const onSortClick = (fieldName) => () => {
    let newOrder =
      columns.find((column) => column.sortfieldName === fieldName).order ===
      'desc'
        ? 'asc'
        : 'desc'

    setColumns(
      columns.map((column) => ({
        ...column,
        active: fieldName === column.sortfieldName,
        order:
          (fieldName === column.sortfieldName &&
            (column.order === 'desc' ? 'asc' : 'desc')) ||
          undefined
      }))
    )

    fieldName === 'podEta' && newOrder === 'desc'
      ? setEarlierClick(true)
      : setEarlierClick(false)

    fieldName === 'transit' && newOrder === 'desc'
      ? setFasterClick(true)
      : setFasterClick(false)

    setShipperSchedules(
      shipperSchedules
        .slice()
        .sort(
          comparator(
            fieldName,
            columns.find((column) => column.sortfieldName === fieldName)
              .order === 'desc'
          )
        )
    )
  }

  const handleDirectCheckBoxChange = (newValue) => {
    setDirectOnly(newValue)
    performFilter(newValue, transitTime, cyCutOffRange)
  }

  const handleTransitTimeSilderMoveChange = (event, newValue) => {
    let newTransitTime = [
      {
        value: newValue[0],
        label: sortedTransitTimeArray.current.find(
          (item) => item.value === newValue[0]
        ).label
      },
      {
        value: newValue[1],
        label: sortedTransitTimeArray.current.find(
          (item) => item.value === newValue[1]
        ).label
      }
    ]
    setTransitTime(newTransitTime)
  }

  const handleTransitTimeSliderCommittedChange = () => {
    performFilter(directOnly, transitTime, cyCutOffRange)
  }

  const handleCyCutOffSliderMoveChange = (event, newValue) => {
    let newCyCutOffRange = [
      {
        value: newValue[0],
        label: sortedCyCutOffArray.current.find(
          (item) => item.value === newValue[0]
        ).label
      },
      {
        value: newValue[1],
        label: sortedCyCutOffArray.current.find(
          (item) => item.value === newValue[1]
        ).label
      }
    ]
    setCyCutOffRange(newCyCutOffRange)
  }

  const handleCyCutOffSliderCommittedChange = () => {
    performFilter(directOnly, transitTime, cyCutOffRange)
  }

  const performFilter = (directOnly, newTransitTime, newCyCutOffRange) => {
    runFilter.current = true
    setLoading(true)
    setEarlierClick(true)
    setFasterClick(false)
    setColumns(
      columns.map((column) => ({
        ...column,
        active: column.sortfieldName === 'podEta',
        order: column.sortfieldName === 'podEta' ? 'desc' : undefined
      }))
    )
    setPage(0)
    const newShipperSchedules = [...originalShipperSchedules]
    setTimeout(() => {
      setShipperSchedules(
        newShipperSchedules
          .filter((schedule) => {
            if (directOnly) {
              return schedule.directShipment
            } else {
              return true
            }
          })
          .filter(
            (schedule) =>
              schedule.transit >= newTransitTime[0].label &&
              schedule.transit <= newTransitTime[1].label
          )
          .filter((schedule) => {
            if (
              newCyCutOffRange &&
              !moment(schedule.cyCutoff, moment.ISO_8601, true).isValid()
            ) {
              return true
            } else {
              return (
                moment(schedule.cyCutoff).isAfter(
                  moment(newCyCutOffRange[0].label)
                ) &&
                moment(schedule.cyCutoff).isBefore(
                  moment(newCyCutOffRange[1].label).add(1, 'days')
                )
              )
            }
          })
      )
      setLoading(false)
    }, 500)
  }

  const handleClearAllButtonClick = () => {
    setDirectOnly(false)
    setTransitTime(minMaxValue)
    setCyCutOffRange(minMaxDateValue)

    performFilter(false, minMaxValue, minMaxDateValue)
  }

  const handleAmendClick = (voyageId) => () => {
    console.log(voyageId)
    console.log(searchCriteria)

    const passSearchCriteria = {
      departDate: searchCriteria.cargoReadyDate,
      arrivalDate: searchCriteria.rangeOfCargoReadyDate,
      from: searchCriteria.portOfLoading,
      to: searchCriteria.portOfDischarge,
      weeks: searchCriteria.weeks,
      carrierSearch: true
    }

    fetchRecords.execute(
      TcalVsManageScheduleApiUrls.GET_BY_ID,
      // {voyageId: location.state.voyageId},
      { customData: { voyageId: voyageId } },
      (data) => {
        populateVessel(data)
      },
      (error) => {
        console.log(error)
        setLoading(false)
      }
    )

    function populateVessel(voyage) {
      fetchRecords.execute(
        TcalVsVesselApiUrls.GET,
        {
          filters: [
            {
              field: 'id',
              operator: EQUAL,
              value: voyage.vesselId
            }
          ]
        },
        (data) => {
          voyage.vesselName = data.content[0].name
          voyage.imoNo = data.content[0].imoNo
          voyage.voyageStatus = voyage.voyageStatus.includes('CVS')
            ? voyage.voyageStatus.slice(voyage.voyageStatus.length - 1)
            : voyage.voyageStatus
          //console.log(voyage)
          history.push({
            pathname: pathMap.TCAL_VS_MANAGE_SCHEDULE_AMEND,
            state: { searchCriteria: passSearchCriteria, voyageData: voyage }
          })
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    }
  }

  return (
    <Grid container spacing={1}>
      <SearchPanel
        searchPortFrom={location.state.from}
        searchPortTo={location.state.to}
        searchArrivalDate={location.state.arrivalDate}
        searchDepartDate={location.state.departDate}
        searchPreferred={null}
        searchToCountryCode={location.state.toCountryCode}
        searchFromCountryCode={location.state.fromCountryCode}
        weeks={location.state.weeks}
      />
      <CngGridItem
        xs={12}
        sm={9}
        shouldHide={
          !loading ? (shipperSchedules.length > 0 ? true : false) : true
        }
      >
        <NoResult
          showNotification={showNotification}
          runFilter={runFilter.current}
          disableButton={true}
          handleClearAllButtonClick={handleClearAllButtonClick}
        />
      </CngGridItem>
      <CngGridItem
        xs={12}
        sm={9}
        shouldHide={!loading && shipperSchedules.length > 0 ? false : true}
      >
        <Card>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <div className={'rs-align-spacing-grey'}>
                <Typography variant='caption'>{`Results ${Math.min(
                  page * rowsPerPage + 1,
                  shipperSchedules.length
                )}-${Math.min(
                  (page + 1) * rowsPerPage,
                  shipperSchedules.length
                )} of ${shipperSchedules.length}`}</Typography>
              </div>
            </Grid>
            <Grid item xs={9}>
              <Grid
                container
                spacing={2}
                justify='flex-end'
                alignItems='flex-start'
              >
                <Grid item>
                  <div className={'rs-align-spacing'}>
                    <Button
                      variant={fasterClick ? 'outlined' : 'text'}
                      onClick={onSortClick('transit')}
                    >
                      {translatedTextsObject.fastest}
                    </Button>
                  </div>
                </Grid>
                <Grid item>
                  <div className={'rs-align-spacing'}>
                    <Button
                      variant={earlierClick ? 'outlined' : 'text'}
                      onClick={onSortClick('podEta')}
                    >
                      {translatedTextsObject.earliest}
                    </Button>
                  </div>
                </Grid>
                <CngGridItem shouldHide={false}>
                  <div className={'rs-align-spacing'}>
                    <MiscComponent
                      shipperSchedules={shipperSchedules}
                      showNotification={showNotification}
                    />
                  </div>
                </CngGridItem>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={column.name + index}
                      className={'vs-table-cell-padding'}
                    >
                      {column.sortfieldName !== '' ? (
                        <TableSortLabel
                          active={column.active}
                          direction={column.order}
                          onClick={onSortClick(column.sortfieldName)}
                        >
                          <div className={'rs-grey'}>{column.name}</div>
                        </TableSortLabel>
                      ) : (
                        <div className={'rs-grey'}>{column.name}</div>
                      )}
                    </TableCell>
                  ))}
                  <TableCell className={'vs-table-cell-padding'}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? shipperSchedules.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : shipperSchedules
                ).map((shipperSchedule) => (
                  <Fragment key={`Fragment-${shipperSchedule.voyageId}`}>
                    <TableRow key={shipperSchedule.voyageId}>
                      <TableCell className={'vs-table-cell-padding'}>
                        <div className={'rs-showContent-bold'}>
                          {shipperSchedule.vesselName}
                        </div>
                        <div className={'rs-showContent-bold'}>
                          {shipperSchedule.service}
                        </div>
                      </TableCell>
                      <TableCell className={'vs-table-cell-padding'}>
                        <div className={'rs-showContent-bold'}>
                          {shipperSchedule.transit} {translatedTextsObject.days}
                        </div>
                        {shipperSchedule.directShipment ? (
                          <div className={'rs-green'}>
                            {' '}
                            {translatedTextsObject.direct}
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell className={'vs-table-cell-padding'}>
                        <div className={'rs-showContent-bold'}>
                          {moment(
                            shipperSchedule.cyCutoff,
                            moment.ISO_8601,
                            true
                          ).isValid()
                            ? moment(shipperSchedule.cyCutoff).format(
                                'D MMM (ddd) HH:mm'
                              )
                            : shipperSchedule.cyCutoff}
                        </div>
                      </TableCell>
                      <TableCell className={'vs-table-cell-padding'}>
                        <div className={'rs-showContent-bold'}>
                          {moment(shipperSchedule.polEtd).format(
                            'D MMM (ddd) HH:mm'
                          )}
                        </div>
                        <div className={'vs-result-content-down'}>
                          <span>
                            <Chip
                              label={<b>{shipperSchedule.pol}</b>}
                              size='small'
                            />
                          </span>
                        </div>
                      </TableCell>
                      <TableCell className={'vs-table-cell-padding'}>
                        <div className={'rs-showContent-bold'}>
                          {moment(shipperSchedule.podEta).format(
                            'D MMM (ddd) HH:mm'
                          )}
                        </div>
                        <div className={'vs-result-content-down'}>
                          <span>
                            <Chip
                              label={<b>{shipperSchedule.pod}</b>}
                              size='small'
                            />
                          </span>
                        </div>
                      </TableCell>
                      <TableCell className={'vs-table-cell-padding'}>
                        <div className={'rs-green'}>
                          <b>{'OPEN'}</b>
                        </div>
                      </TableCell>
                      <TableCell
                        align='right'
                        className={'vs-table-cell-padding'}
                      >
                        <Box display='flex' justifyContent='flex-end'>
                          <CngSecondaryButton
                            onClick={handleAmendClick(shipperSchedule.voyageId)}
                            style={{ width: '90px' }}
                            name='amend'
                          >
                            Amend
                          </CngSecondaryButton>

                          <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={expandInfo(
                              shipperSchedule.voyageId,
                              shipperSchedule.startSeqNo,
                              shipperSchedule.endSeqNo
                            )}
                          >
                            {shipperSchedule.expand ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={7}
                      >
                        <Collapse
                          in={shipperSchedule.expand}
                          timeout='auto'
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <Table size='small' aria-label='purchases'>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <div className={'rs-grey'}>
                                      {translatedTextsObject.transportVia}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className={'rs-grey'}>
                                      {translatedTextsObject.cyCutOff}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className={'rs-grey'}>
                                      {translatedTextsObject.arrive}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className={'rs-grey'}>
                                      {translatedTextsObject.departure}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className={'rs-grey'}>
                                      {translatedTextsObject.voyagerDetails}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className={'rs-grey'}>
                                      {translatedTextsObject.terminal.toUpperCase()}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {voyageLegs
                                  .filter(
                                    (voyageLeg) =>
                                      voyageLeg.voyageId ===
                                      shipperSchedule.voyageId
                                  )
                                  .map((item) =>
                                    item.content.map((leg) =>
                                      leg.listOut ? (
                                        <TableRow
                                          key={`${leg.voyageId}_${leg.seqNo}`}
                                        >
                                          <TableCell>
                                            <div
                                              className={'rs-showContent-bold'}
                                            >
                                              <PortCountryViewField
                                                countryList={country}
                                                portCode={leg.portCode}
                                              />
                                            </div>
                                          </TableCell>
                                          <TableCell>
                                            <div
                                              className={'rs-showContent-bold'}
                                            >
                                              {moment(
                                                leg.cyCutoffTime,
                                                moment.ISO_8601,
                                                true
                                              ).isValid()
                                                ? moment(
                                                    leg.cyCutoffTime
                                                  ).format('D MMM (ddd) HH:mm')
                                                : leg.cyCutoffTime}
                                            </div>
                                          </TableCell>
                                          <TableCell>
                                            <div
                                              className={'rs-showContent-bold'}
                                            >
                                              {item.startSeqNo === leg.seqNo
                                                ? null
                                                : moment(
                                                    leg.arrivalTime
                                                  ).format('D MMM (ddd) HH:mm')}
                                            </div>
                                          </TableCell>
                                          <TableCell>
                                            <div
                                              className={'rs-showContent-bold'}
                                            >
                                              {item.endSeqNo === leg.seqNo
                                                ? null
                                                : moment(
                                                    leg.departureTime
                                                  ).format('D MMM (ddd) HH:mm')}
                                            </div>
                                          </TableCell>

                                          <TableCell>
                                            <div
                                              className={'rs-showContent-bold'}
                                            >
                                              {leg.transportMode === 'SEA' ||
                                              leg.transportMode === 'FEEDER' ||
                                              leg.transportMode === 'WATER' ||
                                              leg.transportMode === 'BARGE'
                                                ? leg.schVesselName === null &&
                                                  leg.schVoyageNo === null
                                                  ? item.endSeqNo === leg.seqNo
                                                    ? ''
                                                    : item.check.find(
                                                        (v) =>
                                                          v.key ===
                                                          leg.voyageId +
                                                            leg.schVoyageNo +
                                                            leg.schVesselName +
                                                            leg.transportMode
                                                      ).startSeq === leg.seqNo
                                                    ? shipperSchedule.vesselName +
                                                      ' ' +
                                                      shipperSchedule.voyageNo
                                                    : leg.transportMode !== null
                                                    ? leg.transportMode +
                                                      ' ' +
                                                      manageScheduleTranslationText.transport.toUpperCase()
                                                    : ''
                                                  : leg.schVesselName +
                                                    ' ' +
                                                    (leg.schVoyageNo === null
                                                      ? ''
                                                      : leg.schVoyageNo)
                                                : leg.transportMode !== null
                                                ? leg.transportMode +
                                                  ' ' +
                                                  manageScheduleTranslationText.transport.toUpperCase()
                                                : ''}
                                            </div>
                                          </TableCell>
                                          <TableCell>
                                            <div
                                              className={'rs-showContent-bold'}
                                            >
                                              <TerminalInitial
                                                withTooltip={
                                                  leg.terminal
                                                    ? leg.terminal.length > 15
                                                    : false
                                                }
                                                longName={leg.terminal}
                                                shortName={
                                                  leg.terminal
                                                    ? leg.terminal.length > 15
                                                      ? leg.terminal
                                                          .substring(0, 15)
                                                          .trim() + '...'
                                                      : leg.terminal
                                                    : ''
                                                }
                                              />
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      ) : null
                                    )
                                  )}

                                <TableRow>
                                  <TableCell colSpan={5}>
                                    <PortsStepper
                                      voyageLegs={voyageLegs}
                                      shipperScheduleVoyageId={
                                        shipperSchedule.voyageId
                                      }
                                      shipperScheduleDirectShipment={
                                        shipperSchedule.directShipment
                                      }
                                      countryList={country}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <ResultPageTablePagination
                    recordLength={shipperSchedules.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Card>
      </CngGridItem>
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>
      <CngGridItem xs={12} sm={3} shouldHide={false}>
        <FilterPanel
          handleClearAllButtonClick={handleClearAllButtonClick}
          preferred={null}
          handleDirectCheckBoxChange={handleDirectCheckBoxChange}
          directOnly={directOnly}
          directOnlyCount={directOnlyCount.current}
          transitTime={transitTime}
          carriers={null}
          cyCutOffRange={cyCutOffRange}
          onCarrierCheckBoxChange={null}
          handleTransitTimeSliderCommittedChange={
            handleTransitTimeSliderCommittedChange
          }
          handleTransitTimeSliderMoveChange={handleTransitTimeSilderMoveChange}
          handleCyCutOffSliderCommittedChange={
            handleCyCutOffSliderCommittedChange
          }
          handleCyCutOffSliderMoveChange={handleCyCutOffSliderMoveChange}
          minMaxValue={minMaxValue}
          minMaxDateValue={minMaxDateValue}
          carrier={true}
        />
      </CngGridItem>
      <ManageCarrierConnectionDialog
        showNotification={showNotification}
        isDialogOpen={isManageCarrierConnectionDialogOpen}
        closeDialog={() => setManageCarrierConnectionDialogOpen(false)}
        connectCarrierPartyId={connectCarrierPartyId}
      />
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData)
  localData.createdDate = DateTimeFormatter.toClientDate(localData.createdDate)
  localData.updatedDate = DateTimeFormatter.toClientDate(localData.updatedDate)
  return localData
}

function toServerDataFormat(localData) {
  localData.createdDate = DateTimeFormatter.toServerDate(localData.createdDate)
  localData.updatedDate = DateTimeFormatter.toServerDate(localData.updatedDate)
  return DataFlattener.unflatten(localData)
}

const FormPropertiesResult = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormPropertiesResult
