import {
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core'

import React from 'react'
import RemoveIcon from '@material-ui/icons/Clear'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import moment from 'moment'

const RecentSearch = (props) => {
  const { departDate, plus, weeks } = SearchScheduleTranslationText()

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {props.rows.slice(0, 2).map((row) => (
            <TableRow
              key={row.searchHistoryId}
              onClick={props.onRowClick(row.searchHistoryId)}
              selected={row.selected}
            >
              <TableCell component='th' scope='row'>
                <div className={'rs-content'}>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/static/images/countries/' +
                        row.polCountryCode +
                        '.svg'
                      }
                      className={'rs-countryImageRight'}
                    />
                  </div>
                  <div>
                    <div className={'rs-showContent'}>{row.polDesc}</div>
                    <div className={'rs-showContentWithTransparent'}>
                      {row.polCountryName}
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell align='right'>
                <div>
                  <span>
                    <Chip label={<b>{row.portOfLoading}</b>} size='small' />
                  </span>
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <span>
                    <Chip label={<b>&#8594;</b>} size='small' />
                  </span>
                </div>
              </TableCell>
              <TableCell align='left'>
                <div>
                  <span>
                    <Chip label={<b>{row.portOfDischarge}</b>} size='small' />
                  </span>
                </div>
              </TableCell>
              <TableCell component='th' scope='row'>
                <div className={'rs-content'}>
                  <div>
                    <div className={'rs-showContent'}>{row.podDesc}</div>
                    <div className={'rs-showContentWithTransparent'}>
                      {row.podCountryName}
                    </div>
                  </div>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/static/images/countries/' +
                        row.podCountryCode +
                        '.svg'
                      }
                      className={'rs-countryImageLeft'}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell align='left'>
                <div className={'rs-showContentWithTransparent'}>
                  {departDate}
                </div>
                <div className={'rs-showContent'}>
                  {moment(row.cargoReadyDate).format('DD-MMM-yyyy')}
                </div>
              </TableCell>
              <TableCell align='left'>
                <div className={'rs-showContent'}>
                  {plus}{' '}
                  {moment(row.cargoDeliveryDate).diff(
                    moment(row.cargoReadyDate),
                    'weeks'
                  )}{' '}
                  {weeks.toLowerCase()}
                </div>
              </TableCell>
              {!props.carrier ? (
                <TableCell align='left'>
                  <div className={'rs-showContent'}>
                    {' '}
                    {
                      props.options[
                        props.options.findIndex(
                          (option) => option.value === row.searchType
                        )
                      ]?.label
                    }
                  </div>
                </TableCell>
              ) : null}
              <TableCell align='right'>
                <IconButton
                  onClick={props.removeRowClick(
                    row.searchHistoryId,
                    row.version
                  )}
                  color={'default'}
                >
                  <RemoveIcon fontSize='small' />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default RecentSearch
