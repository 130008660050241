import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import MailIcon from '@material-ui/icons/Mail'
import RestoreIcon from '@material-ui/icons/Restore'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import SendUsFeedbackDialog from './feedback/SendUsFeedbackDialog.js'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const {
  button: { CngPrimaryButton, CngSecondaryButton }
} = components
const { CodeMaintenanceType } = constants

const NoResult = ({
  showNotification,
  runFilter,
  disableButton,
  handleClearAllButtonClick
}) => {
  const translatedTextsObject = SearchScheduleTranslationText()
  const [isFeedbackDialogOpen, setFeedbackDialogOpen] = useState(false)
  const [countryNameByCode, setCountryNameByCode] = useState([])
  const history = useHistory()
  const { from, to } = history.location.state
  const { fetchRecords } = useServices()

  useEffect(() => {
    fetchRecords.execute(
      `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/get`,
      {
        filters: [
          {
            field: 'code',
            operator: 'in',
            value: [from, to]
          }
        ],
        customData: { codeMType: CodeMaintenanceType.PORT }
      },
      (res) => {
        setCountryNameByCode(
          res.content.reduce(
            (res, data) => {
              res[data.code] = data.descriptionEn
              return res
            },
            {
              [from]: from,
              [to]: to
            }
          )
        )
      },
      (error) => {
        setCountryNameByCode({
          [from]: from,
          [to]: to
        })
        console.error(error.message)
      }
    )
  }, [])

  function closeDialog() {
    setFeedbackDialogOpen(false)
  }

  return (
    <div className={'vs-noresult'}>
      <img
        src={
          process.env.PUBLIC_URL + '/static/images/vesselSchedule/noresults.svg'
        }
        alt='No Result'
      ></img>
      <Typography variant='h3' style={{ fontWeight: 'bold' }}>
        {translatedTextsObject.noResult}
      </Typography>
      <Typography variant='caption' style={{ padding: '10px', opacity: '0.5' }}>
        {runFilter
          ? translatedTextsObject.noResultFoundAfterFilter
          : translatedTextsObject.noResultFound}
      </Typography>
      {runFilter ? (
        <CngSecondaryButton
          name='reset'
          onClick={handleClearAllButtonClick}
          startIcon={<RestoreIcon />}
        >
          {translatedTextsObject.resetFilters}
        </CngSecondaryButton>
      ) : !disableButton ? (
        <CngPrimaryButton
          name='request'
          startIcon={<MailIcon />}
          onClick={() => setFeedbackDialogOpen(true)}
        >
          {translatedTextsObject.sendUsRequest}
        </CngPrimaryButton>
      ) : null}

      <SendUsFeedbackDialog
        isFeedbackDialogOpen={isFeedbackDialogOpen}
        closeDialog={closeDialog}
        showNotification={showNotification}
        countryNameByCode={countryNameByCode}
      />
    </div>
  )
}

export default NoResult
