import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import ResultPage from './ResultPage.js'
import SearchPage from './SearchPage.js'
import TranslationText from './SearchScheduleTranslationText'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_VesselSchedule'

function WrappedSearchPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <div className={'outer-container'}>
          <SearchPage showNotification={showSnackbar} {...props} />
        </div>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedResultPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <ResultPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export { WrappedSearchPage as SearchPage, WrappedResultPage as ResultPage }
