import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'

import React from 'react'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'

function NoResultProgressBar() {
  const translatedTextsObject = SearchScheduleTranslationText()

  return (
    <Card>
      <Grid container spacing={2} justify='flex-end' alignItems='flex-start'>
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          <Box mt={1} mr={1}>
            <object
              type='image/svg+xml'
              data={
                process.env.PUBLIC_URL +
                '/static/images/vesselSchedule/button_loading.svg'
              }
            />
          </Box>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className={'rs-grey'}>{translatedTextsObject.carrier}</div>
              </TableCell>
              <TableCell>
                <div className={'rs-grey'}>
                  {translatedTextsObject.vessel}/{' '}
                  {translatedTextsObject.service}
                </div>
              </TableCell>
              <TableCell>
                <div className={'rs-grey'}>
                  {translatedTextsObject.transitTime}
                </div>
              </TableCell>
              <TableCell>
                <div className={'rs-grey'}>
                  {translatedTextsObject.cyCutOff}
                </div>
              </TableCell>
              <TableCell>
                <div className={'rs-grey'}>
                  {translatedTextsObject.departPOL}
                </div>
              </TableCell>
              <TableCell>
                <div className={'rs-grey'}>
                  {translatedTextsObject.arrivePOD}
                </div>
              </TableCell>
              <TableCell>
                <Box>&nbsp;&nbsp;</Box>
              </TableCell>
              <TableCell>
                <Box>&nbsp;&nbsp;&nbsp;&nbsp;</Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
              <TableRow key={`${i} - noresult`}>
                <TableCell>
                  <Box>
                    <object
                      type='image/svg+xml'
                      data={
                        process.env.PUBLIC_URL +
                        '/static/images/vesselSchedule/logo_loading.svg'
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell colSpan={5}>
                  <Box>
                    <object
                      type='image/svg+xml'
                      data={
                        process.env.PUBLIC_URL +
                        '/static/images/vesselSchedule/row_loading.svg'
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box width='30px'>
                    <object
                      type='image/svg+xml'
                      data={
                        process.env.PUBLIC_URL +
                        '/static/images/vesselSchedule/ships_loading.svg'
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box minWidth='80px' minHeight='100%'>
                    <object
                      type='image/svg+xml'
                      data={
                        process.env.PUBLIC_URL +
                        '/static/images/vesselSchedule/button_loading.svg'
                      }
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default NoResultProgressBar
