import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'

import React from 'react'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

const DirectShipmentFilterComponent = (props) => {
  const translatedTextsObject = SearchScheduleTranslationText()

  return (
    <Grid container='true' container item class={'rs-filter-card'}>
      <CngGridItem class={'rs-bg-grey'} xs={12}>
        <Typography class={'rs-filter-subheader'}>
          {translatedTextsObject.directTranshipment}
        </Typography>
      </CngGridItem>
      <CngGridItem xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={props.directOnlyCount === 0}
              checked={props.directOnly}
              name='directOnly'
              onChange={(e) =>
                props.handleDirectCheckBoxChange(e.target.checked)
              }
            />
          }
          label={
            translatedTextsObject.directOnly +
            ' (' +
            props.directOnlyCount +
            ')'
          }
        />
      </CngGridItem>
    </Grid>
  )
}

export default DirectShipmentFilterComponent
