import React, { useContext } from 'react'

import CngWizard from '../cngcomponent/CngWizard.js'
import EnterCarrierInformationStep from './EnterCarrierInformationStep.js'
import GetNotifiedStep from './GetNotifiedStep.js'
import ManageCarrierConnectionDialogContext from './ManageCarrierConnectionDialogContext.js'
import SearchScheduleTranslationText from '../SearchScheduleTranslationText'
import SelectCarrierStep from './SelectCarrierStep.js'
import StepContainer from './StepContainer.js'

function ManageCarrierConnectionWizard() {
  const translatedTextsObject = SearchScheduleTranslationText()
  const dialogContext = useContext(ManageCarrierConnectionDialogContext)
  const {
    wizard: { setStepOnTransitioning }
  } = dialogContext

  const steps = [
    {
      label: translatedTextsObject.selectCarrier,
      content: (
        <StepContainer>
          <SelectCarrierStep />
        </StepContainer>
      )
    },
    {
      label: translatedTextsObject.enterCarrierInformation,
      content: (
        <StepContainer>
          <EnterCarrierInformationStep />
        </StepContainer>
      )
    },
    {
      label: translatedTextsObject.getNotified,
      content: (
        <StepContainer>
          <GetNotifiedStep />
        </StepContainer>
      )
    }
  ]

  return (
    <CngWizard
      steps={steps}
      stepperProps={{
        orientation: 'vertical'
      }}
      stepContentProps={{
        TransitionProps: {
          onEnter: () => setStepOnTransitioning(true),
          onEntered: () => setStepOnTransitioning(false)
        }
      }}
      hideCompleted
    />
  )
}

export default ManageCarrierConnectionWizard
