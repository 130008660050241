import { Card, CardContent, Grid } from '@material-ui/core'

import CarrierFilterComponents from './CarrierFilterComponent'
import CyCutOffFilterComponents from './CyCutOffFilterComponent'
import DirectShipmentFilterComponent from './DirectShipmentFilterComponent'
import FilterHeaderComponent from './FilterHeaderComponent'
import PreferredFilterComponent from './PreferredFilterComponent'
import React from 'react'
import TransitTimeFilterComponents from './TransitTimeFilterComponent'

function FilterPanel(props) {
  return (
    <Grid md={12}>
      <Card>
        <CardContent>
          <FilterHeaderComponent
            handleClearAllButtonClick={props.handleClearAllButtonClick}
          />
          {!props.carrier ? (
            <PreferredFilterComponent preferred={props.preferred} />
          ) : null}
          <DirectShipmentFilterComponent
            directOnly={props.directOnly}
            directOnlyCount={props.directOnlyCount}
            handleDirectCheckBoxChange={props.handleDirectCheckBoxChange}
          />

          {!props.carrier ? (
            <CarrierFilterComponents
              carriers={props.carriers}
              onCarrierCheckBoxChange={props.onCarrierCheckBoxChange}
            />
          ) : null}
          <TransitTimeFilterComponents
            transitTime={props.transitTime}
            handleTransitTimeSliderMoveChange={
              props.handleTransitTimeSliderMoveChange
            }
            handleTransitTimeSliderCommittedChange={
              props.handleTransitTimeSliderCommittedChange
            }
            minMaxValue={props.minMaxValue}
          />
          <CyCutOffFilterComponents
            cyCutOffRange={props.cyCutOffRange}
            minMaxDateValue={props.minMaxDateValue}
            handleCyCutOffSliderMoveChange={
              props.handleCyCutOffSliderMoveChange
            }
            handleCyCutOffSliderCommittedChange={
              props.handleCyCutOffSliderCommittedChange
            }
          />
        </CardContent>
      </Card>
    </Grid>
  )
}

export default FilterPanel
