import React, { useContext } from 'react'
import { Yup, components, constants, useServices } from 'cng-web-lib'

import ManageCarrierConnectionDialogContext from './ManageCarrierConnectionDialogContext.js'
import ManageCarrierConnectionWizard from './ManageCarrierConnectionWizard.js'
import SearchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls.js'
import check from 'check-types'

const {
  form : {
    CngViewForm,
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  },
  table: { useDefaultNotification }
} = components
const { FormState } = constants

function ManageCarrierConnectionForm() {
  const { createRecord } = useServices()
  const dialogContext = useContext(ManageCarrierConnectionDialogContext)
  const {
    closeDialog,
    showNotification,
    form: { connectCarrierPartyId, isSubmitting, setSubmitting }
  } = dialogContext
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  function onSubmit(data) {
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setSubmitting(false)
      closeDialog()
      showSuccessNotification(`Your request has been submitted.
      Your reference No is ${response.refNo}`)
    }

    function onError(error) {
      console.log('connection-request error', error.message)
      setSubmitting(false)
      showErrorNotification(error.message)
    }

    setSubmitting(true)
    createRecord.execute(
      SearchScheduleApiUrls.CONNECTION_REQUEST_CREATE,
      data,
      onSuccess,
      onError
    )
  }

  return (
    <CngViewForm
      formikProps={{
        initialValues: getInitialValues(connectCarrierPartyId),
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody closeDialog={closeDialog} isSubmitting={isSubmitting} />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
   />
  )
}

function FormBody({ closeDialog, isSubmitting }) {
  return <ManageCarrierConnectionWizard />
}

function getInitialValues(connectCarrierPartyId) {
  return {
    carrierPartyId: connectCarrierPartyId,
    bookRefNo: '',
    annualShipmentTeu: '',
    contractNo: '',
    custNo: '',
    blNo: '',
    govTaxId: '',
    comments: '',
    notify: true,
    email: ''
  }
}

const validationSchema = Yup.object({
  carrierPartyId: Yup.number()
    .transform((value, original) => {
      if (check.number(value)) {
        return value
      }
      return -1
    })
    .min(0, 'Carrier is required'),
  email: Yup.string().when('notify', {
    is: true,
    then: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter an email address'),
    otherwise: Yup.string()
  })
})

export default ManageCarrierConnectionForm
