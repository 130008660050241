import { combineBooleanObjects, components } from 'cng-web-lib'

import FormPropertiesSearch from 'src/views/vesselschedule/searchschedule/FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import { useHistory } from 'react-router-dom'

const {
  form: { CngViewForm }
} = components

function ViewPage({ showNotification }) {
  const history = useHistory()
  const fieldsShouldHideMap = { preferred: true }

  function onSubmit(data) {
    data.arrivalDate = moment(moment(data.departDate).format('DD-MMM-YYYY'))
      .add(data.weeks, 'weeks')
      .format('YYYY-MM-DD')
    data.carrierSearch = true
    let submittedData = { ...data }
    submittedData.departDate = moment(
      moment(submittedData.departDate).format('DD-MMM-YYYY')
    ).format('YYYY-MM-DD')

    console.log('submit', submittedData)

    history.push({
      pathname: pathMap.CARRIER_SEARCH_SCHEDULE_RESULT,
      state: submittedData
    })
  }

  return (
    <div>
      <Grid
        container
        classes={{
          container: 'vessel-search-container'
        }}
      >
        <Grid item xs={12}>
          <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
              <FormPropertiesSearch.Fields
                disabled={false}
                showNotification={showNotification}
                shouldHideMap={combineBooleanObjects(
                  fieldsShouldHideMap,
                  shouldHideMap
                )}
              />
            )}
            formikProps={{
              ...FormPropertiesSearch.formikProps,
              onSubmit: onSubmit,
              validateOnChange: true
            }}
            toClientDataFormat={FormPropertiesSearch.toClientDataFormat}
            toServerDataFormat={FormPropertiesSearch.toServerDataFormat}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ViewPage
