import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import ManageCarrierConnectionDialogContext from './ManageCarrierConnectionDialogContext.js'
import ManageCarrierConnectionForm from './ManageCarrierConnectionForm.js'
import Paper from '@material-ui/core/Paper'
import SearchScheduleTranslationText from '../SearchScheduleTranslationText'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function ManageCarrierConnectionDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  connectCarrierPartyId
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const [isStepOnTransitioning, setStepOnTransitioning] = useState(false)
  const translatedTextsObject = SearchScheduleTranslationText()

  return (
    <ManageCarrierConnectionDialogContext.Provider
      value={{
        closeDialog,
        showNotification,
        form: {
          connectCarrierPartyId,
          isSubmitting,
          setSubmitting
        },
        wizard: {
          isStepOnTransitioning,
          setStepOnTransitioning
        }
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <ManageCarrierConnectionForm />
          </Paper>
        }
        dialogTitle={
          <>
            <b>{translatedTextsObject.requestCarrierConnection}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='sm'
      />
    </ManageCarrierConnectionDialogContext.Provider>
  )
}

export default ManageCarrierConnectionDialog
