import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import ResultPage from './ResultPage'
import SearchPage from './SearchPage'
import SearchScheduleTranslationText from '../SearchScheduleTranslationText'
import useBreadcrumbNameMap from '../../../../navigations/breadcrumbs/Breadcrumb_VesselSchedule'

function WrappedCarrierSearchSchedulePage(props) {
  const translatedTextsObject = SearchScheduleTranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <div className={'outer-container'}>
          <SearchPage showNotification={showSnackbar} {...props} />
        </div>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedCarrierSearchResultPage(props) {
  const translatedTextsObject = SearchScheduleTranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.vesselSchedule}
      renderPage={(showSnackbar) => (
        <ResultPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedCarrierSearchSchedulePage as CarrierSearchPage,
  WrappedCarrierSearchResultPage as CarrierResultPage
}
