import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from '@material-ui/core'

import React from 'react'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

const CarrierFilterComponents = (props) => {
  const translatedTextsObject = SearchScheduleTranslationText()

  return (
    <Grid container='true' container item className={'rs-filter-card'}>
      <CngGridItem className={'rs-bg-grey'} xs={12}>
        <Typography className={'rs-filter-subheader'}>
          {translatedTextsObject.carrier}
        </Typography>
      </CngGridItem>
      <CngGridItem xs={12}>
        <CheckboxGroup
          carriers={props.carriers}
          onChange={props.onCarrierCheckBoxChange}
        />
      </CngGridItem>
    </Grid>
  )
}

const CheckboxGroup = ({ carriers, onChange }) => (
  <FormControl component='fieldset'>
    <FormGroup row={true}>
      {carriers
        .filter((carrier) => carrier.name === 'ALL')
        .map((carrier, index) => (
          <FormControlLabel
            key={carriers.length - 1}
            control={
              <Checkbox
                disabled={!carrier.eligible}
                checked={
                  carriers.filter((carrier) => carrier.checked === true)
                    .length === carriers.length
                }
                indeterminate={
                  carriers.filter((carrier) => carrier.checked === true)
                    .length > 0 &&
                  carriers.filter((carrier) => carrier.checked === true)
                    .length < carriers.length
                }
                onChange={onChange(carriers.length - 1)}
                inputProps={{ 'aria-label': 'indeterminate checkbox' }}
              />
            }
            label={`All Carrier (${carrier.count})`}
          />
        ))}
      {carriers
        .filter((carrier) => carrier.name !== 'ALL')
        .map((carrier, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                disabled={!carrier.eligible}
                checked={carrier.checked}
                onChange={onChange(index)}
              />
            }
            label={`${carrier.name} (${carrier.count})`}
          />
        ))}
    </FormGroup>
  </FormControl>
)

export default CarrierFilterComponents
