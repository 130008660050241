import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import VsMigrationSyncLogKeys from 'src/constants/locale/key/VsMigrationSyncLog'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './VsMigrationSyncLogMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  source: "",
  destination: "",
  vesselName: "",
  voyageNo: "",
  migrationTime: "",
  retry: 0,
  startTime: "",
  endTime: "",
  processStatus: "",
  errorLog: "",
  content: "",
  processResponse: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.VS_MIGRATION_SYNC_LOG)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let vsMigrationSyncLog = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.TITLE
    )
    let source = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.SOURCE
    )
    let destination = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.DESTINATION
    )
    let vesselName = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.VESSEL_NAME
    )
    let voyageNo = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.VOYAGE_NO
    )
    let migrationTime = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.MIGRATION_TIME
    )
    let retry = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.RETRY
    )
    let startTime = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.START_TIME
    )
    let endTime = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.END_TIME
    )
    let processStatus = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.PROCESS_STATUS
    )
    let errorLog = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.ERROR_LOG
    )
    let content = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.CONTENT
    )
    let processResponse = translate(
      Namespace.VS_MIGRATION_SYNC_LOG,
      VsMigrationSyncLogKeys.PROCESS_RESPONSE
    )

    return {
      vsMigrationSyncLog,
      source,
      destination,
      vesselName,
      voyageNo,
      migrationTime,
      retry,
      startTime,
      endTime,
      processStatus,
      errorLog,
      content,
      processResponse
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.vsMigrationSyncLog} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.source}>
            <CngTextField
              name="source"
              label={translatedTextsObject.source}
              disabled={true}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.destination}>
            <CngTextField
              name="destination"
              label={translatedTextsObject.destination}
              disabled={true}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselName}>
            <CngTextField
              name="vesselName"
              label={translatedTextsObject.vesselName}
              disabled={true}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNo}>
            <CngTextField
              name="voyageNo"
              label={translatedTextsObject.voyageNo}
              disabled={true}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.migrationTime}>
            <CngDateField
              name="migrationTime"
              label={translatedTextsObject.migrationTime}
              format='YYYY-MM-DD HH:mm:ss'
              disabled={true}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.retry}>
            <CngTextField
              name="retry"
              type="number"
              label={translatedTextsObject.retry}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.startTime}>
            <CngDateField
              name="startTime"
              label={translatedTextsObject.startTime}
              format='YYYY-MM-DD HH:mm:ss'
              disabled={true}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.endTime}>
            <CngDateField
              name="endTime"
              label={translatedTextsObject.endTime}
              format='YYYY-MM-DD HH:mm:ss'
              disabled={true}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processStatus}>
            <CngTextField
              name="processStatus"
              label={translatedTextsObject.processStatus}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.errorLog}>
            <CngTextField
              name="errorLog"
              label={translatedTextsObject.errorLog}
              disabled={true}
              multiline
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.content}>
            <CngTextField
              name="content"
              label={translatedTextsObject.content}
              disabled={true}
              multiline
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processResponse}>
            <CngTextField
              name="processResponse"
              label={translatedTextsObject.processResponse}
              disabled={true}
              multiline
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.migrationTime = DateTimeFormatter.toClientDateTime(
    localData.migrationTime,
    'YYYY-MM-DD HH:mm:ss'
  );
  localData.startTime = DateTimeFormatter.toClientDateTime(
    localData.startTime,
    'YYYY-MM-DD HH:mm:ss'
  );
  localData.endTime = DateTimeFormatter.toClientDateTime(
    localData.endTime,
    'YYYY-MM-DD HH:mm:ss'
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.migrationTime = DateTimeFormatter.toServerDateTime(
    localData.migrationTime,
    'YYYY-MM-DD HH:mm:ss'
  );
  localData.startTime = DateTimeFormatter.toServerDateTime(
    localData.startTime,
    'YYYY-MM-DD HH:mm:ss'
  );
  localData.endTime = DateTimeFormatter.toServerDateTime(
    localData.endTime,
    'YYYY-MM-DD HH:mm:ss'
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
