import {
  DateTimeFormatter,
  Yup,
  components,
  constants,
  useServices,
  useTranslation
} from 'cng-web-lib'

import Box from '@material-ui/core/Box'
import CancelButton from 'src/components/button/CancelButton.js'
import Namespace from 'src/constants/locale/Namespace.js'
import React from 'react'
import SearchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls.js'
import SearchScheduleTranslationText from '../SearchScheduleTranslationText'
import SubmitButton from 'src/components/button/SubmitButton.js'
import TcalVsVoyageKeys from 'src/constants/locale/key/TcalVsVoyage.js'

const {
  form: {
    CngForm,
    field: { CngTextField }
  },
  table: { useDefaultNotification }
} = components
const { FormState } = constants

function SendUsFeedbackForm({
  arrivalDate,
  departDate,
  from,
  to,
  closeDialog,
  isSubmitting,
  updateSubmitting,
  showNotification
}) {
  const { translate } = useTranslation(['common', Namespace.TCAL_VS_VOYAGE])
  const translatedTextsObject = SearchScheduleTranslationText()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)
  const { createRecord } = useServices()

  function onSubmit(data) {
    function onSuccess(response) {
      console.log('onSuccess', response)
      updateSubmitting(false)
      closeDialog()
      showSuccessNotification(
        `Your feedback has been submitted.
         Your reference No is ${response.refNo}`
      )
    }

    function onError(error) {
      console.log('error', error.message)
      updateSubmitting(false)
      showErrorNotification(error.message)
    }

    updateSubmitting(true)
    createRecord.execute(
      SearchScheduleApiUrls.FEEDBACK_CREATE,
      {
        pol: from,
        pod: to,
        polEtd: DateTimeFormatter.toServerDate(departDate),
        podEta: DateTimeFormatter.toServerDate(arrivalDate),
        subject: data.subject,
        email: data.email,
        message: data.body
      },
      onSuccess,
      onError
    )
  }

  return (
    <CngForm
      formikProps={{
        initialValues: { subject: '', email: '', body: '' },
        onSubmit: onSubmit,
        validationSchema: Yup.object({
          subject: Yup.string().required('Please enter a subject'),
          email: Yup.string()
            .email('Please enter a valid email address')
            .required('Please enter an email address')
        })
      }}
      bodySection={
        <>
          <Box mt={2}>
            <CngTextField
              name='subject'
              label={`${translate(
                Namespace.TCAL_VS_VOYAGE,
                TcalVsVoyageKeys.FEEDBACK.SUBJECT
              )}`}
              disabled={isSubmitting}
              inputProps={{ maxLength: 100 }}
            />
          </Box>
          <Box mt={2}>
            <CngTextField
              name='email'
              label={`${translate('common', 'email')}`}
              disabled={isSubmitting}
              inputProps={{ maxLength: 100 }}
            />
          </Box>
          <Box mt={2}>
            <CngTextField
              name='body'
              label={`${translate(
                Namespace.TCAL_VS_VOYAGE,
                TcalVsVoyageKeys.FEEDBACK.BODY
              )}`}
              multiline
              rows={6}
              placeholder={translatedTextsObject.detailOfSearch}
              disabled={isSubmitting}
              inputProps={{ maxLength: 500 }}
            />
          </Box>
          <Box display='flex' flexDirection='row-reverse' mt={2}>
            <Box>
              <SubmitButton isSubmitting={isSubmitting}>Submit</SubmitButton>
            </Box>
            <Box mr={2}>
              <CancelButton onClick={closeDialog} disabled={isSubmitting} />
            </Box>
            <Box flexGrow={1}></Box>
          </Box>
        </>
      }
      buttonSection={null}
      formState={FormState.COMPLETED}
      fieldLevel=''
      renderButtonSection={() => {
        return null
      }}
    />
  )
}

export default SendUsFeedbackForm
