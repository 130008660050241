import { Box, Card, CardContent, Chip, Grid } from '@material-ui/core'
import React, { useEffect, useRef } from 'react'

import PortSelectAutoComplete from './PortSelectAutoComplete'
import SearchIcon from '@material-ui/icons/Search'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import { components } from 'cng-web-lib'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { withStyles } from '@material-ui/core/styles'
import WordBox from './cngcomponent/WordBox'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngDateField }
  },
  button: { CngPrimaryButton, CngSecondaryButton },
  CngGridItem
} = components

function SearchPanel(props) {
  const {
    searchPortFrom,
    searchPortTo,
    searchDepartDate,
    searchArrivalDate,
    searchToCountryCode,
    searchFromCountryCode,
    weeks,
    carbonFootprintResults
  } = props
  const [portFormField, , { setValue: setPortFromField }] = useField('from')
  const [portToField, , { setValue: setPortToField }] = useField('to')
  const [, , { setValue: setDepartDate }] = useField('departDate')
  const [, , { setValue: setArrivalDate }] = useField('arrivalDate')
  const [, , { setValue: setFromCountryCodeField }] = useField(
    'fromCountryCode'
  )
  const [, , { setValue: setToCountryCodeField }] = useField('toCountryCode')
  const [, , { setValue: setPollingId }] = useField('pollingId')
  const [weeksField, , { setValue: setWeeks }] = useField('weeks')
  const portFromKeyRef = useRef(uuid())
  const portToKeyRef = useRef(uuid())
  const translatedTextsObject = SearchScheduleTranslationText()
  const { submitForm } = useFormikContext()

  useEffect(() => {
    setPortFromField(searchPortFrom)
    setPortToField(searchPortTo)
    setDepartDate(moment(searchDepartDate).format('DD-MMM-YYYY'))
    setArrivalDate(searchArrivalDate)
    setFromCountryCodeField(searchFromCountryCode)
    setToCountryCodeField(searchToCountryCode)
    setWeeks(weeks)
    setPollingId('')

    portFromKeyRef.current = uuid()
    portToKeyRef.current = uuid()
  }, [])

  const onChangePortFromAutoComplete = (value) => {
    setFromCountryCodeField(
      value !== null && value.length >= 2 ? value.substring(0, 2) : ''
    )
  }

  const onChangePortToAutoComplete = (value) => {
    setToCountryCodeField(
      value !== null && value.length >= 2 ? value.substring(0, 2) : ''
    )
  }

  const swapClick = () => {
    setPortFromField(portToField.value)
    setPortToField(portFormField.value)

    portFromKeyRef.current = uuid()
    portToKeyRef.current = uuid()
  }

  const onSearch = () => {
    if (props.resultPage) {
      props.handleOnSearch()
    } else {
      submitForm()
    }
  }

  const DarkerDisabledCngTextField = withStyles({
    root: {
      '& .MuiInputBase-root.Mui-disabled': {
        color: 'rgba(0, 0, 0, 1)'
      }
    }
  })(CngTextField)

  return (
    <Grid item md={12}>
      <Card>
        <CardContent>
          <Grid container={true} item md={12} spacing={1}>
            <Grid container={true} item xs={12} md={12} spacing={1}>
              <CngGridItem item xs={12} sm={5}>
                <PortSelectAutoComplete
                  name='from'
                  label={translatedTextsObject.portFrom}
                  keyRef={portFromKeyRef.current}
                  onChangeAutoComplete={onChangePortFromAutoComplete}
                  required
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={1}>
                <Box mt={2} ml={2} mr={2}>
                  <Chip
                    label={<span style={{ fontSize: '20px' }}>&#8644;</span>}
                    onClick={swapClick}
                    style={{
                      display: 'flex',
                      row: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  />
                </Box>
              </CngGridItem>
              <CngGridItem item xs={12} sm={5}>
                <PortSelectAutoComplete
                  name='to'
                  label={translatedTextsObject.portTo}
                  keyRef={portToKeyRef.current}
                  onChangeAutoComplete={onChangePortToAutoComplete}
                  required
                />
              </CngGridItem>
            </Grid>
            <Grid container={true} item xs={12} md={12} spacing={1}>
              <CngGridItem item xs={12} sm={4}>
                <CngDateField
                  name='departDate'
                  label={translatedTextsObject.departDate}
                  format='DD-MMM-YYYY'
                  shouldDisableDate={(date) => {
                    return moment(moment().format('DD-MMM-YYYY')).isAfter(
                      moment(date)
                    )
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <Box display='flex'>
                  <Box width='20%'>
                    <CngSecondaryButton
                      onClick={() => {
                        typeof weeksField.value === 'number' &&
                          weeksField.value >= 2
                          ? setWeeks(weeksField.value - 1)
                          : setWeeks(1)
                      }}
                      name='minus'
                      className={'search-button'}
                    >
                      <span style={{ fontSize: '20px' }}>
                        <b>{translatedTextsObject.minus}</b>
                      </span>
                    </CngSecondaryButton>
                  </Box>
                  <Box ml={1} mr={1} width='25%'>
                    <DarkerDisabledCngTextField
                      name='weeks'
                      label={translatedTextsObject.weeks}
                      disabled={true}
                    />
                  </Box>
                  <Box width='20%' fontSize='30px'>
                    <CngSecondaryButton
                      onClick={() => {
                        typeof weeksField.value === 'number' &&
                          weeksField.value < 8
                          ? setWeeks(weeksField.value + 1)
                          : setWeeks(8)
                      }}
                      name='add'
                      className={'search-button'}
                    >
                      <span style={{ fontSize: '20px' }}>
                        <b>{translatedTextsObject.plus}</b>
                      </span>
                    </CngSecondaryButton>
                  </Box>
                </Box>
              </CngGridItem>
              <CngGridItem item xs={12} sm={3}>
                <CngPrimaryButton
                  name='search'
                  //type='submit'
                  onClick={onSearch}
                  startIcon={<SearchIcon />}
                  className={'search-button'}
                >
                  {translatedTextsObject.search}
                </CngPrimaryButton>
              </CngGridItem>
            </Grid>
            <Grid container={true} item xs={12} md={12} spacing={1}>
              <Box mt={2} ml={1}>
                <WordBox
                  isTonPerTeu={true}
                  label={translatedTextsObject.cfcTonPerTeu}
                />
              </Box>
            </Grid>
            <Grid container={true} item xs={12} md={12} spacing={1}>
              <Box mr={2} mt={1} ml={1}>
                <WordBox
                  label={translatedTextsObject.cO2EmissionTotal}
                  value={carbonFootprintResults[0]}
                  unit={translatedTextsObject.cfcTonsUnit}
                />
              </Box>
              <Box mt={1}>
                <WordBox
                  label={translatedTextsObject.energyConsumption}
                  value={carbonFootprintResults[1]}
                  unit={translatedTextsObject.cfcMegajouleUnit}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default SearchPanel
