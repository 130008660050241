import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ClearIcon from '@material-ui/icons/Clear'
import CngLinearProgressWithLabel from './cngcomponent/CngLinearProgressWithLabel'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import { components } from 'cng-web-lib'
import { makeStyles } from '@material-ui/core/styles'

const {
  button: { CngPrimaryButton, CngSecondaryButton },
  CngGridItem
} = components

function PollingProgressBar({
  pollingCount,
  pollingProgress,
  pollingId,
  refreshData,
  hideInfoBar
}) {
  const [closeInfo, setCloseInfo] = useState(false)
  const [cancelRefresh, setCancelRefresh] = useState(false)
  const translatedTextsObject = SearchScheduleTranslationText()

  function progressBarDetails() {
    let percentageProgressBar =
      pollingCount > 0
        ? (pollingProgress.current[1] / pollingProgress.current[0]) * 100
        : pollingProgress.current[0] !== 0 &&
          pollingProgress.current[1] !== pollingProgress.current[0]
        ? 0
        : 100

    let showHideProgressBar =
      pollingCount > 0
        ? true
        : pollingProgress.current[0] !== 0 &&
          pollingProgress.current[1] !== pollingProgress.current[0]
        ? true
        : typeof pollingId === 'number'

    return [percentageProgressBar, showHideProgressBar]
  }

  const useStyles = makeStyles((theme) => ({
    cardcontent: {
      padding: 12,
      '&:last-child': {
        paddingBottom: 10
      },
      backgroundColor: '#f5f5fa'
    },
    cardcontentNoGrey: {
      padding: 12,
      '&:last-child': {
        paddingBottom: 10
      }
    },
    wrapIcon: {
      align: 'left',
      display: 'inline-flex'
    },
    greenIcon: {
      color: '#94ebbb'
    }
  }))

  const classes = useStyles()

  return (
    <Box>
      {progressBarDetails()[1] ? (
        <Box mb={1}>
          <Grid container={true} spacing={1}>
            <CngGridItem
              item
              xs={12}
              shouldHide={typeof pollingId === 'number'}
            >
              <CngLinearProgressWithLabel value={progressBarDetails()[0]} />
            </CngGridItem>
          </Grid>
        </Box>
      ) : null}
      {!progressBarDetails()[1] && !cancelRefresh ? (
        <Box mb={1}>
          <Card>
            <CardContent className={classes.cardcontentNoGrey}>
              <Grid container direction='row'>
                <CngGridItem item xs={6} sm={8}>
                  <div>
                    <div className={'rs-showContent'}>
                      <Typography
                        variant='caption'
                        className={classes.wrapIcon}
                      >
                        <CheckCircleOutlineIcon className={classes.greenIcon} />
                        &nbsp; <b>{translatedTextsObject.searchCompleted}</b>
                      </Typography>
                    </div>
                    <div className={'rs-showContentWithTransparent'}>
                      {translatedTextsObject.refreshTheResult}
                    </div>
                  </div>
                </CngGridItem>
                <CngGridItem item xs={6} sm={4} align='right'>
                  <CngSecondaryButton
                    size='small'
                    onClick={() => setCancelRefresh(true)}
                  >
                    {translatedTextsObject.cancel}
                  </CngSecondaryButton>
                  &nbsp;&nbsp;
                  <CngPrimaryButton size='small' onClick={refreshData}>
                    {translatedTextsObject.refresh}
                  </CngPrimaryButton>
                </CngGridItem>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      ) : null}
      {progressBarDetails()[1] &&
      !(typeof pollingId === 'number') &&
      !closeInfo &&
      !hideInfoBar ? (
        <Box mb={1}>
          <Card>
            <CardContent className={classes.cardcontent}>
              <Grid container direction='row'>
                <CngGridItem item xs={11}>
                  <Typography variant='caption' className={classes.wrapIcon}>
                    <InfoOutlinedIcon />
                    &nbsp; {translatedTextsObject.refreshNote}
                  </Typography>
                </CngGridItem>
                <CngGridItem item xs={1} align='right'>
                  <ClearIcon
                    fontSize='small'
                    onClick={() => setCloseInfo(true)}
                  />
                </CngGridItem>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      ) : null}
    </Box>
  )
}

export default PollingProgressBar
