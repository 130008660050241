function useStepperHandler(
  steps,
  activeStep,
  setActiveStep,
  completed,
  setCompleted,
  disabledSteps
) {
  function getTotalSteps() {
    return steps.length
  }

  function getCompletedSteps() {
    return Object.keys(completed).length
  }

  function isLastStep() {
    return activeStep === getTotalSteps() - 1
  }

  function isAllStepsCompleted() {
    return getCompletedSteps() === getTotalSteps()
  }

  function handleNext() {
    const newActiveStep = getNextStep()
    setActiveStep(newActiveStep)
  }

  function getNextStep() {
    for (let step = activeStep; step < getTotalSteps(); step++) {
      const newActiveStep =
        isLastStep() && !isAllStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : step + 1

      if (!disabledSteps) {
        return newActiveStep
      } else if (!disabledSteps[newActiveStep]) {
        return newActiveStep
      }
    }

    return getTotalSteps() - 1
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => getPreviousStep(prevActiveStep))
  }

  function getPreviousStep(activeStep) {
    for (let step = activeStep - 1; step > 0; step--) {
      if (!disabledSteps) {
        return step
      } else if (!disabledSteps[step]) {
        return step
      }
    }
    return 0
  }

  function handleStep(step) {
    return () => {
      setActiveStep(step)
    }
  }

  function handleComplete() {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext()
  }

  function handleReset() {
    setActiveStep(0)
    setCompleted({})
  }

  return {
    isAllStepsCompleted,
    isLastStep,
    handleBack,
    handleComplete,
    handleNext,
    handleReset,
    handleStep
  }
}

export default useStepperHandler
