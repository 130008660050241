import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography
} from '@material-ui/core'
import {
  DataFlattener,
  DateTimeFormatter,
  components,
  constants,
  useServices
} from 'cng-web-lib'
import { useEffect, useRef, useState } from 'react'

import Loading from './Loading'
import PortSelectAutoComplete from './PortSelectAutoComplete'
import PreferredSettingMenu from './PreferredSettingMenu'
import React from 'react'
import RecentSearch from './RecentSearch'
import SearchIcon from '@material-ui/icons/Search'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import makeValidationSchema from './MakeValidationSchema'
import moment from 'moment'
import searchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls'
import { v4 as uuid } from 'uuid'
import { withStyles } from '@material-ui/core/styles'


const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngTextField, CngDateField, CngRadioGroupField }
  },
  button: { CngPrimaryButton, CngSecondaryButton },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  departDate: moment().format('DD-MMM-YYYY'),
  arrivalDate: DateTimeFormatter.toClientDate(
    new Date(
      new Date().setTime(new Date().getTime() + 4 * 7 * 24 * 60 * 60 * 1000)
    )
  ),
  preferred: 'DEFAULT',
  from: '',
  to: '',
  fromCountryCode: '',
  toCountryCode: '',
  pollingId: '',
  weeks: 4,
  carrierSearch: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema,
  validateOnChange: true
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { fetchRecords, deleteRecord } = useServices()
  const translatedTextsObject = SearchScheduleTranslationText()
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const countryCodeByPortCodeRef = useRef([])
  const [portFormField, , { setValue: setPortFromField }] = useField('from')
  const [portToField, , { setValue: setPortToField }] = useField('to')
  const [, , { setValue: setDepartDateField }] = useField('departDate')
  const [, , { setValue: setArrivalDateField }] = useField('arrivalDate')
  const [, , { setValue: setPreferredField }] = useField('preferred')
  const [, , { setValue: setFromCountryCodeField }] = useField(
    'fromCountryCode'
  )
  const [, , { setValue: setToCountryCodeField }] = useField('toCountryCode')
  const [weeksField, , { setValue: setWeeks }] = useField('weeks')

  const options = [
    { label: translatedTextsObject.allCarrier, value: 'DEFAULT' },
    { label: translatedTextsObject.preferredCarrier, value: 'CARRIER' },
    { label: translatedTextsObject.preferredPortPair, value: 'PORT' }
  ]
  // const portFromKeyRef = useRef(uuid())
  // const portToKeyRef = useRef(uuid())
  const [portFromKeyRef, setPortFromKeyRef] = useState()
  const [portToKeyRef, setPortToKeyRef] = useState()



  useEffect(() => {
    fetchRecords.execute(
      searchScheduleApiUrls.RECENT_SEARCH,
      undefined,
      (data) => {
        let FromApi = data.content
        console.log(FromApi)
        setRows(FromApi)
        setLoading(false)
      },
      (error) => {
        console.log(error)
        setLoading(false)
      }
    )
  }, [])

  const DarkerDisabledCngTextField = withStyles({
    root: {
      '& .MuiInputBase-root.Mui-disabled': {
        color: 'rgba(0, 0, 0, 1)'
      }
    }
  })(CngTextField)

  const onRowClick = (id) => async () => {
    const newRows = rows.map((item) => ({
      ...item,
      selected: false
    }))

    const index = rows.findIndex((row) => row.searchHistoryId === id)
    const row = rows[index]

    newRows[index] = { ...row, selected: true }

    setRows(newRows)

    await setDepartDateField(
      moment(row.cargoReadyDate).isAfter(moment())
        ? moment(row.cargoReadyDate).format('DD-MMM-YYYY')
        : moment().format('DD-MMM-YYYY')
    )
    await setPortFromField(row.portOfLoading)
    await setPortToField(row.portOfDischarge)
    await setArrivalDateField(row.cargoDeliveryDate)
    await setPreferredField(row.searchType)
    await setFromCountryCodeField(row.polCountryCode)
    await setToCountryCodeField(row.podCountryCode)
    await setWeeks(
      moment(row.cargoDeliveryDate).diff(moment(row.cargoReadyDate), 'weeks')
    )

    await setPortFromKeyRef(uuid())
    setPortToKeyRef(uuid())

  }

  const removeRowClick = (id, version) => (e) => {
    const newRows = rows.filter((item) => item.searchHistoryId !== id)
    setRows(newRows)
    deleteRecord.execute(
      searchScheduleApiUrls.DELETE_RECENT_SEARCH,
      { id: id, version: version },
      () => {
        console.log('successfully remove the record with id : ' + id)
      },
      (error) => {
        console.log(error)
      }
    )
    e.stopPropagation()
  }

  const swapClick = async () => {
    await setPortFromField(portToField.value)
    await setPortToField(portFormField.value)

    await setPortFromKeyRef(uuid())
    setPortToKeyRef(uuid())
  }

  const onChangePortFromAutoComplete = (value) => {
    setFromCountryCodeField(
      value !== null && value.length >= 2 ? value.substring(0, 2) : ''
    )
  }

  const onChangePortToAutoComplete = (value) => {
    setToCountryCodeField(
      value !== null && value.length >= 2 ? value.substring(0, 2) : ''
    )
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={5} shouldHide={shouldHideMap.from}>
            <PortSelectAutoComplete
              name='from'
              label={translatedTextsObject.portFrom}
              keyRef={portFromKeyRef}
              onChangeAutoComplete={onChangePortFromAutoComplete}
            //  required
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={1} shouldHide={shouldHideMap.from}>
            <Box mt={2}>
              <Chip
                label={<span style={{ fontSize: '20px' }}>&#8644;</span>}
                onClick={swapClick}
                style={{
                  display: 'flex',
                  row: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              />
            </Box>
          </CngGridItem>
          <CngGridItem xs={12} sm={5} shouldHide={shouldHideMap.to}>
            <PortSelectAutoComplete
              name='to'
              label={translatedTextsObject.portTo}
              keyRef={portToKeyRef}
              onChangeAutoComplete={onChangePortToAutoComplete}
            //   required
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.departDate}>
            <CngDateField
              name='departDate'
              label={translatedTextsObject.departDate}
              disabled={disabled}
              format='DD-MMM-YYYY'
              shouldDisableDate={(date) => {
                return moment(moment().format('DD-MMM-YYYY')).isAfter(
                  moment(date)
                )
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4}>
            <Box display='flex'>
              <Box width='25%'>
                <CngSecondaryButton
                  onClick={() => {
                    typeof weeksField.value === 'number' &&
                      weeksField.value >= 2
                      ? setWeeks(weeksField.value - 1)
                      : setWeeks(1)
                  }}
                  name='minus'
                  disabled={disabled}
                  className={'search-button'}
                >
                  <span style={{ fontSize: '20px' }}>
                    <b>{translatedTextsObject.minus}</b>
                  </span>
                </CngSecondaryButton>
              </Box>
              <Box ml={1} mr={1} width='30%' fontWeight='fontWeightBold'>
                <DarkerDisabledCngTextField
                  name='weeks'
                  label={translatedTextsObject.weeks}
                  disabled={true}
                  style={{ color: 'black' }}
                />
              </Box>
              <Box width='25%' fontSize='30px'>
                <CngSecondaryButton
                  onClick={() => {
                    typeof weeksField.value === 'number' && weeksField.value < 8
                      ? setWeeks(weeksField.value + 1)
                      : setWeeks(8)
                  }}
                  name='add'
                  disabled={disabled}
                  className={'search-button'}
                >
                  <span style={{ fontSize: '20px' }}>
                    <b>{translatedTextsObject.plus}</b>
                  </span>
                </CngSecondaryButton>
              </Box>
            </Box>
          </CngGridItem>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.search}>
            <CngPrimaryButton
              name='search'
              type='submit'
              disabled={disabled}
              startIcon={<SearchIcon />}
              className={'search-button'}
            >
              {translatedTextsObject.search}
            </CngPrimaryButton>
          </CngGridItem>
          <CngGridItem xs={10} sm={11} shouldHide={shouldHideMap.preferred}>
            <CngRadioGroupField
              labelSpacing={12}
              disabled={disabled}
              name='preferred'
              label={translatedTextsObject.preferred}
              options={options}
              direction='row'
            />
          </CngGridItem>
          <CngGridItem xs={2} sm={1} shouldHide={shouldHideMap.preferred}>
            <PreferredSettingMenu />
          </CngGridItem>
          <CngGridItem
            xs={12}
            sm={12}
            shouldHide={!loading ? (rows.length > 0 ? false : true) : false}
          >
            <Typography variant='h6' className={'blue-label'}>
              {translatedTextsObject.previouslySearch}
            </Typography>
          </CngGridItem>
          <CngGridItem
            xs={12}
            sm={12}
            shouldHide={!loading && rows.length > 0 ? false : true}
          >
            <RecentSearch
              rows={rows}
              options={options}
              onRowClick={onRowClick}
              removeRowClick={removeRowClick}
              carrier={
                shouldHideMap.preferred === null
                  ? false
                  : shouldHideMap.preferred
              }
            />
          </CngGridItem>
          <CngGridItem xs={12} shouldHide={loading ? false : true}>
            <Loading loading={loading} />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData)
  localData.createdDate = DateTimeFormatter.toClientDate(localData.createdDate)
  localData.updatedDate = DateTimeFormatter.toClientDate(localData.updatedDate)
  return localData
}

function toServerDataFormat(localData) {
  localData.createdDate = DateTimeFormatter.toServerDate(localData.createdDate)
  localData.updatedDate = DateTimeFormatter.toServerDate(localData.updatedDate)
  return DataFlattener.unflatten(localData)
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
