import StepContent from '@material-ui/core/StepContent'
import { withStyles } from '@material-ui/core'

const StyledStepContent = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.divider,
    marginLeft: 19,
    paddingLeft: 27
  }
}))(StepContent)

export default StyledStepContent
