import VsMigrationSyncLogApiUrls from 'src/apiUrls/VsMigrationSyncLogApiUrls'
import VsMigrationSyncLogFormProperties from './VsMigrationSyncLogFormProperties'
import React, { useState } from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngEditForm }
} = components


function VsMigrationSyncLogEditForm({
    showNotification,
    id,
    onFetchPreSuccess = () => {
        //do nothing
     },
    items
}) {
    return (
        <CngEditForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            showNotification={showNotification}
            bodySection={
                <VsMigrationSyncLogFormProperties.Fields
                    disabled={false}
                    showNotification={showNotification}
                    isEdit={true}
                    items={items}
                />
            }
            formikProps={VsMigrationSyncLogFormProperties.formikProps}
            toClientDataFormat={VsMigrationSyncLogFormProperties.toClientDataFormat}
            toServerDataFormat={VsMigrationSyncLogFormProperties.toServerDataFormat}
            fetch={{
                url: VsMigrationSyncLogApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
            update={{
                url: VsMigrationSyncLogApiUrls.PUT
            }}
            id={id}
        />
    )
}

export default VsMigrationSyncLogEditForm
