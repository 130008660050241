import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography
} from '@material-ui/core'

import PropTypes from 'prop-types'
import React from 'react'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

export default function CngLinearProgressWithLabel(props) {
  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <Grid container direction='row'>
          <CngGridItem item xs={12}>
            {' '}
            <CircularProgress color='primary' size={10} /> &nbsp;
            <Typography variant='caption'>Loading</Typography> &nbsp;
            <Typography variant='caption'>{`${Math.round(
              props.value
            )}%`}</Typography>
          </CngGridItem>
        </Grid>
        <CngGridItem item xs={12}>
          <LinearProgress variant='determinate' {...props} />
        </CngGridItem>
      </Box>
    </Box>
  )
}

CngLinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired
}
