import StepConnector from '@material-ui/core/StepConnector'
import { withStyles } from '@material-ui/core'

const StyledStepConnector = withStyles((theme) => ({
  vertical: {
    marginLeft: 19
  },
  horizontal: {
    minWidth: 50
  },
  line: {
    borderColor: theme.palette.divider
  }
}))(StepConnector)

export default StyledStepConnector
