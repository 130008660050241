import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core'
import { LogOut, MoreHorizontal } from 'react-feather'
import React, { useState } from 'react'

import ExportCsv from '@material-table/exporters/csv'
import ExportPdf from 'src/views/vesselschedule/component/ExportPdf.js'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import clsx from 'clsx'
import { components } from 'cng-web-lib'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

const {
  table: { useDefaultNotification }
} = components

const toolbarClasses = makeStyles((theme) => ({
  buttonIcons: {
    '& .svg-inline--fa': { fontSize: 'inherit' }
  },
  menu: {
    '& .MuiMenu': {
      '&-paper': { borderRadius: 10 },
      '&-list': {
        maxWidth: '60vw',
        padding: 2,
        '& .MuiListItem-root:not(.Mui-disabled)': {
          letterSpacing: 0.21,
          fontSize: '.9em',
          padding: '8px 10px',
          minWidth: '12em',
          '&:hover': {
            backgroundColor:
              theme.palette.component.popoverMenu.listItemHoverFill
          }
        }
      }
    },
    '& .ng-mattbl-popover-checkbox': {
      padding: 0,
      paddingRight: theme.spacing(1)
    },
    '& .ng-mattbl-popover-title': {
      backgroundColor:
        theme.palette.component.textFormControl.dropdownMenuSelectedFill,
      borderRadius: 8,
      fontWeight: 900,
      color: theme.palette.text.navActive,
      padding: '10px 8px',
      marginBottom: theme.spacing(0.5)
    }
  }
}))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem)

export default function ExpandMenus({ showNotification, shipperSchedules }) {
  const translatedTextsObject = SearchScheduleTranslationText()
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const [columns, setColumns] = useState([
    {
      title: translatedTextsObject.carrier.toUpperCase(),
      field: 'carrierName'
    },
    {
      title: translatedTextsObject.vessel.toUpperCase(),
      field: 'vesselName'
    },
    {
      title: translatedTextsObject.service.toUpperCase(),
      field: 'service'
    },
    {
      title: translatedTextsObject.transitTime.toUpperCase(),
      field: 'transit'
    },
    {
      title: translatedTextsObject.direct.toUpperCase(),
      field: 'directShipment'
    },
    {
      title: translatedTextsObject.cyCutOff.toUpperCase(),
      field: 'cyCutoff'
    },
    {
      title: translatedTextsObject.departPOL.split(' ')[1],
      field: 'pol'
    },
    {
      title: translatedTextsObject.departPOL.split(' ')[0],
      field: 'polEta'
    },
    {
      title: translatedTextsObject.arrivePOD.split(' ')[1],
      field: 'pod'
    },
    {
      title: translatedTextsObject.arrivePOD.split(' ')[0],
      field: 'podEta'
    },
    {
      title: 'TRANSPORT MODE',
      field: 'transportMode'
    }
  ])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const performExport = (format) => () => {
    try {
      let exportShipperSchedules = shipperSchedules.map((shipperSchedule) => {
        return {
          carrierName: shipperSchedule.carrierName,
          vesselName: shipperSchedule.vesselName,
          service: shipperSchedule.service,
          transit: shipperSchedule.transit + ' ' + translatedTextsObject.days,
          directShipment: shipperSchedule.directShipment
            ? translatedTextsObject.direct
            : '',
          cyCutoff: moment(
            shipperSchedule.cyCutoff,
            moment.ISO_8601,
            true
          ).isValid()
            ? moment(shipperSchedule.cyCutoff).format('D MMM (ddd) HH:mm')
            : shipperSchedule.cyCutoff,
          polEta: moment(shipperSchedule.polEta).format('D MMM (ddd) HH:mm'),
          pol: shipperSchedule.pol,
          podEta: moment(shipperSchedule.podEta).format('D MMM (ddd) HH:mm'),
          pod: shipperSchedule.pod,
          transportMode:
            shipperSchedule.transportMode.includes('V') ||
            shipperSchedule.transportMode.includes('F') ||
            shipperSchedule.transportMode.includes('B')
              ? shipperSchedule.transportMode.includes('R') ||
                shipperSchedule.transportMode.includes('T') ||
                shipperSchedule.transportMode.includes('L')
                ? 'Vessel, Rail'
                : 'Vessel'
              : shipperSchedule.transportMode.includes('R') ||
                shipperSchedule.transportMode.includes('T') ||
                shipperSchedule.transportMode.includes('L')
              ? 'Rail'
              : ''
        }
      })
      console.log('Export Shipper :' + exportShipperSchedules)
      format === 'CSV'
        ? ExportCsv(columns, exportShipperSchedules, 'Results')
        : ExportPdf(columns, exportShipperSchedules, 'Results', null)
      showSuccessNotification('Successfully export data to Results.' + format)
    } catch (err) {
      showErrorNotification(err.message)
    }

    setAnchorEl(null)
  }

  return (
    <Box>
      <IconButton
        color={'secondary'}
        onClick={handleClick}
        className={clsx(toolbarClasses.buttonIcons)}
      >
        <MoreHorizontal size='16px' />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        className={clsx(toolbarClasses.menu)}
      >
        <MenuItem onClick={performExport('CSV')}>
          <ListItemIcon>
            <LogOut size='16px' />
          </ListItemIcon>
          <Typography variant='inherit' style={{ fontSize: 'small' }}>
            Export
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
