import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    source: Yup.string(),
    destination: Yup.string(),
    vesselName: Yup.string(),
    voyageNo: Yup.string(),
    migrationTime: Yup.date().validFormat("YYYY-MM-DD HH:mm:ss").typeError("Invalid date format. Only \"YYYY-MM-DD HH:mm:ss\" format is valid."),
    retry: Yup.number(),
    startTime: Yup.date().validFormat("YYYY-MM-DD HH:mm:ss").typeError("Invalid date format. Only \"YYYY-MM-DD HH:mm:ss\" format is valid."),
    endTime: Yup.date().validFormat("YYYY-MM-DD HH:mm:ss").typeError("Invalid date format. Only \"YYYY-MM-DD HH:mm:ss\" format is valid."),
    processStatus: Yup.string(),
    errorLog: Yup.string(),
    content: Yup.string(),
    processResponse: Yup.string(),
  })
}

export default makeValidationSchema
