import React, { useContext } from 'react'

import Box from '@material-ui/core/Box'
import ManageCarrierConnectionDialogContext from './ManageCarrierConnectionDialogContext.js'
import SearchScheduleTranslationText from '../SearchScheduleTranslationText'
import { Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngCheckboxField, CngTextField }
  }
} = components

function GetNotifiedStep() {
  const translatedTextsObject = SearchScheduleTranslationText()
  const dialogContext = useContext(ManageCarrierConnectionDialogContext)
  const {
    form: { isSubmitting }
  } = dialogContext
  const [notifyField] = useField('notify')
  const [, , { setValue: setEmailField }] = useField('email')

  return (
    <Box>
      <Box>
        <CngCheckboxField
          disabled={isSubmitting}
          name='notify'
          label={
            <Typography variant='body2' gutterBottom>
              {translatedTextsObject.notifyMe}
            </Typography>
          }
          onChange={(event) => {
            if (!event.target.checked) {
              setEmailField('')
            }
          }}
        />
      </Box>
      {notifyField.value && (
        <Box>
          <CngTextField
            name='email'
            label={translatedTextsObject.emailAddress}
            disabled={isSubmitting}
            inputProps={{ maxLength: 100 }}
          />
        </Box>
      )}
    </Box>
  )
}

export default GetNotifiedStep
