import React, { useEffect, useRef, useState } from 'react'

import Tooltip from '@material-ui/core/Tooltip'

export default function TooltipsDiv(props) {
  const divRef = useRef(null)
  const [allowTooltip, setAllowTooltip] = useState(false)

  useEffect(() => {
    if (
      !allowTooltip &&
      divRef.current.scrollWidth > divRef.current.offsetWidth
    ) {
      setAllowTooltip(true)
    }
  }, [])

  if (allowTooltip) {
    return (
      <Tooltip title={props.text}>
        <div ref={divRef} className={'truncate_portPair'}>
          {props.text}
        </div>
      </Tooltip>
    )
  }
  return (
    <div ref={divRef} className={'truncate_portPair'}>
      {props.text}
    </div>
  )
}
