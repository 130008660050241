import React, { useContext } from 'react'

import Box from '@material-ui/core/Box'
import CancelButton from 'src/components/button/CancelButton.js'
import ManageCarrierConnectionDialogContext from './ManageCarrierConnectionDialogContext.js'
import SearchScheduleTranslationText from '../SearchScheduleTranslationText'
import StepperContext from '../cngcomponent/StepperContext.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import check from 'check-types'
import { components } from 'cng-web-lib'

const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  },
  button: { CngPrimaryButton }
} = components

function StepContainer({ children }) {
  return (
    <Box mt={1}>
      <Box>{children}</Box>
      <StepButtonContainer />
    </Box>
  )
}

function StepButtonContainer() {
  const translatedTextsObject = SearchScheduleTranslationText()
  const { errors } = useFormikContext()
  const stepperContext = useContext(StepperContext)
  const dialogContext = useContext(ManageCarrierConnectionDialogContext)
  const { handleNext, isLastStep } = stepperContext
  const {
    closeDialog,
    form: { isSubmitting },
    wizard: { isStepOnTransitioning }
  } = dialogContext

  function shouldDisableContinueButton() {
    return check.nonEmptyString(errors.carrierPartyId)
  }

  return isStepOnTransitioning ? null : (
    <Box display='flex' flexDirection={'row-reverse'} mt={1}>
      {isLastStep() ? (
        <SubmitButton isSubmitting={isSubmitting}>
          {translatedTextsObject.sendRequest}
        </SubmitButton>
      ) : (
        <CngPrimaryButton
          onClick={handleNext}
          disabled={shouldDisableContinueButton()}
        >
          {translatedTextsObject.continueText}
        </CngPrimaryButton>
      )}
      <Box mr={1}>
        <CancelButton onClick={closeDialog} disabled={isSubmitting} />
      </Box>
    </Box>
  )
}

export default StepContainer
