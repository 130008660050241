import Avatar from '@material-ui/core/Avatar'
import PropTypes from 'prop-types'
import React from 'react'
import StepIcon from '@material-ui/core/StepIcon'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

const useAvatarStepIconStyles = makeStyles((theme) => ({
  root: { color: theme.palette.background.stepperNormalText },
  active: {
    backgroundColor: theme.palette.background.stepperActiveFill,
    boxShadow: theme.shadows[10],
    color: theme.palette.background.stepperActiveText
  },
  completed: {
    backgroundColor: theme.palette.background.stepperActiveFill,
    color: theme.palette.background.stepperCompletedText
  }
}))

const useStepIconStyles = makeStyles((theme) => ({
  root: {
    width: 40,
    height: 40
  },
  text: {
    fontSize: '0.6rem'
  }
}))

function StyledStepIcon(props) {
  const { active, completed, hideCompleted, Icon } = props
  const avatarStepIconClasses = useAvatarStepIconStyles()
  const stepIconClasses = useStepIconStyles()

  if (Icon) {
    return (
      <Avatar
        className={clsx(avatarStepIconClasses.root, {
          [avatarStepIconClasses.active]: active,
          [avatarStepIconClasses.completed]: completed && !hideCompleted
        })}
      >
        <Icon size='20' />
      </Avatar>
    )
  }

  return (
    <StepIcon
      {...props}
      classes={{
        root: stepIconClasses.root,
        text: stepIconClasses.text
      }}
      active={active || (hideCompleted && completed)}
      completed={completed && !hideCompleted}
    />
  )
}

StyledStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number,
  steps: PropTypes.array
}

export default StyledStepIcon
