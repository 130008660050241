import { Box, Grid, Slider, Tooltip, Typography } from '@material-ui/core'

import React from 'react'
import SearchScheduleTranslationText from './SearchScheduleTranslationText'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

const CyCutOffFilterComponents = (props) => {
  const translatedTextsObject = SearchScheduleTranslationText()

  return (
    <Grid container item class={'rs-filter-card'}>
      <CngGridItem class={'rs-bg-grey'} xs={12}>
        <Typography class={'rs-filter-subheader'}>
          {translatedTextsObject.cyCutOff}{' '}
        </Typography>
      </CngGridItem>
      <CngGridItem xs={12}>
        <Box mx={3.5} mt={1}>
          <Slider
            disabled={props.cyCutOffRange.length === 0}
            value={
              props.cyCutOffRange.length > 0
                ? [props.cyCutOffRange[0].value, props.cyCutOffRange[1].value]
                : [0, 0]
            }
            onChange={props.handleCyCutOffSliderMoveChange}
            onChangeCommitted={props.handleCyCutOffSliderCommittedChange}
            valueLabelDisplay='auto'
            valueLabelFormat={(value) => {
              if (props.cyCutOffRange.length <= 0) {
                return value
              }

              const date = props.cyCutOffRange.find(
                (date) => date.value === value
              )
              return date ? date.label : value
            }}
            ValueLabelComponent={ValueLabelTooltip}
            min={
              props.minMaxDateValue.length > 0
                ? props.minMaxDateValue[0].value
                : 0
            }
            max={
              props.minMaxDateValue.length > 0
                ? props.minMaxDateValue[1].value
                : 0
            }
            marks={
              props.minMaxDateValue.length > 0
                ? props.minMaxDateValue.map((date) => {
                    return {
                      value: date.value,
                      label: date.label
                    }
                  })
                : []
            }
          />
        </Box>
      </CngGridItem>
    </Grid>
  )
}

function ValueLabelTooltip(props) {
  const { children, open, value } = props

  return (
    <Tooltip open={open} enterTouchDelay={0} placement='top' title={value}>
      {children}
    </Tooltip>
  )
}

export default CyCutOffFilterComponents
