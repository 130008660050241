import Namespace from 'src/constants/locale/Namespace'
import { Yup } from 'cng-web-lib'
import { constants } from 'cng-web-lib'
import moment from 'moment'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    'Invalid date format. Only "DD-MMM-YYYY" format is valid.'
  )

  return Yup.object({
    from: Yup.string()

      .required(requiredMessage)
      .notOneOf(
        [Yup.ref('to')],
        'Port To and Port From is required and cannot be the same.'
      )
      .nullable(),
    to: Yup.string()
      .notOneOf(
        [Yup.ref('from')],
        'Port To and Port From is required and cannot be the same.'
      )
      .required(requiredMessage)
      .nullable(),
    departDate: Yup.date()
      .validFormat('DD-MMM-YYYY')
      .typeError(dateTypeErrorMessage)
      .min(moment().format('DD-MMM-YYYY'), "Depart Date can't be before today.")
  })
}

export default makeValidationSchema
