import React from 'react'
import StepLabel from '@material-ui/core/StepLabel'
import StyledStepIcon from './StyledStepIcon.js'

function CngStepLabel(props) {
  const { label, step, ...stepLabelProps } = props

  return (
    <StepLabel
      StepIconComponent={StyledStepIcon}
      StepIconProps={{ Icon: step.icon }}
      {...stepLabelProps}
    >
      {step.label || label}
    </StepLabel>
  )
}

export default CngStepLabel
